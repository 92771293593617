import { Directive, ElementRef, OnChanges } from '@angular/core';

@Directive({
	selector: '[iconMode]'
})
export class IconModeNameDirective implements OnChanges {

	private El: ElementRef;

	constructor(el: ElementRef) {
		this.El = el;

		this.renameIcons();
	}

	async ngOnChanges() {

		console.log('event change');

		await this.renameIcons();
	}

	/**
	 * adiciona a tag nos icons para a correta renderização
	 */
	private async renameIcons() {

		//DELAY DE CARREGAMENTO
		await new Promise(r => setTimeout(r, 10));

		// console.log(this.El);

		const name: string = this.El.nativeElement.getAttribute('name');
		const ios: string = this.El.nativeElement.getAttribute('ios');
		const md: string = this.El.nativeElement.getAttribute('md');

		if (!name || name && ['outline', 'sharp'].some(it => name.includes(it))) return;

		if (!this.El || !this.El.nativeElement) return;

		// this.El.nativeElement.setAttribute('name', name + '-outline');

		if (!ios)
			this.El.nativeElement.setAttribute('ios', name + '-outline');
		if (!md)
			this.El.nativeElement.setAttribute('md', name + '-sharp');
	}

}
