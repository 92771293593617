<google-map [class]="Class" [id]="MapId" [options]="Options" [center]="Options?.center"
	style="height: 100% !important;">

	<map-marker #markerElem="mapMarker" *ngFor="let marker of Markers" [position]="marker.position"
		[label]="marker.label" [title]="marker?.title" [options]="marker?.options" (mapDragend)="OnDrag($event, marker)"
		(mapClick)="OnOpenInfo(markerElem, marker.info)">
	</map-marker>

	<map-kml-layer #kmlLayer="mapKmlLayer" [hidden]="!PolygonUrl" [url]="PolygonUrl">
	</map-kml-layer>

	<map-polyline *ngIf="PolylinePaths" [options]="PolylineOptions">
	</map-polyline>

	<!-- <map-polygon></map-polygon> -->

	<!-- <map-info-window>{{ infoContent }}</map-info-window> -->
</google-map>

<ion-fab slot="fixed" vertical="bottom" horizontal="end">
	<ion-fab-button size="small" (click)="OnChangeStyle($event)">
		<ion-icon name="map-outline"></ion-icon>
	</ion-fab-button>
</ion-fab>