<ion-header>
	<ion-toolbar class="ion-padding-end">
		<!-- <ion-title>Imagem</ion-title> -->

		<ion-button style="margin-right: -16px;" fill="clear" color="dark" shape="round" slot="end" (click)="Close()">
			<ion-icon slot="icon-only" name="close-outline"></ion-icon>
		</ion-button>

	</ion-toolbar>
</ion-header>
<ion-content class="ion-padding padding-horizontal">

	<ion-grid style="height: 100%;">
		<ion-row style="height: calc(100% - 55px);">
			<div class="ion-justify-content-center ion-align-content-center"
				style="display: flex; height: 100%; width: 100%;">

				<image-cropper 
						(imageCropped)="imageCropped($event)" 
						(imageLoaded)="imageLoaded()"
						(loadImageFailed)="loadImageFailed()" 
						[imageChangedEvent]="imageChangedEvent"
						[maintainAspectRatio]="true" 
						[transform]="Transform" 
						[canvasRotation]="CanvasRotation"
						[roundCropper]="false" 
						[aspectRatio]="16/9" 
						[disabled]="false" 
						[resizeToWidth]="ImageSize?.Width"
						format="jpeg" 
						(cropperReady)="cropperReady()" 
						class="ion-no-padding"></image-cropper>
			</div>
		</ion-row>
		<ion-row class="ion-padding-top">
			<ion-col size="9">
				<input #file hidden type="file" (change)="fileChangeEvent($event)"
					[accept]="'image/jpg, image/jpeg, image/png'" />
				<ion-button fill="solid" color="medium" (click)="file?.click()" i18n=":@@common.select-image">Escolha a
					imagem</ion-button>
				<span class="fix-filename">{{Filename||''}}</span>
			</ion-col>
			<ion-col size="3" class="ion-justify-content-end">

				<ion-buttons class="ion-justify-content-end">

					<ion-button shape="round" fill="clear" class="ion-no-padding" [disabled]="!CropperReady"
						(click)="OnResetTranforms()">
						<ion-icon slot="icon-only" name="refresh-outline"></ion-icon>
					</ion-button>

					<ion-button shape="round" fill="clear" class="ion-no-padding" [disabled]="!CropperReady"
						(click)="OnRotateLeft()">
						<ion-icon slot="icon-only" name="return-up-back-outline"></ion-icon>
					</ion-button>
					<ion-button shape="round" fill="clear" class="ion-no-padding" [disabled]="!CropperReady"
						(click)="OnRotateRight()">
						<ion-icon slot="icon-only" name="return-up-forward-outline"></ion-icon>
					</ion-button>

					<ion-button shape="round" fill="clear" class="ion-no-padding" [disabled]="!CropperReady"
						(click)="OnFlipHorizontal()">
						<ion-icon slot="icon-only" name="repeat"></ion-icon>
					</ion-button>

					<ion-button shape="round" fill="clear" style="transform: rotate(90deg)" class="ion-no-padding"
						[disabled]="!CropperReady" (click)="OnFlipVertical()">
						<ion-icon slot="icon-only" name="repeat"></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>
<ion-footer>
	<ion-toolbar class="ion-padding-horizontal">
		<ion-button style="margin-right: -16px;" slot="end" fill="solid" color="medium" (click)="SaveImage()">Guardar
		</ion-button>
	</ion-toolbar>
</ion-footer>