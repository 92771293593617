import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnChanges, AfterContentInit, ElementRef } from '@angular/core';
import { AsyncUtils } from '@utils/AsyncUtils';

@Component({
	selector: 'input-radio-search',
	templateUrl: './input-radio-search.component.html',
	styleUrls: ['./input-radio-search.component.scss'],
})
export class InputRadioSearchComponent implements OnInit, OnChanges {

	@ViewChild(CdkVirtualScrollViewport, { static: false }) cdkVirtualScroll: CdkVirtualScrollViewport;

	@Input('data') Data: any[] = [];
	@Input('disabled') IsDisabled: boolean = false;
	@Input('value') FieldValue: Object = {};

	@Input('textPlaceholder') Placeholder: string = 'indique o valor';
	@Input('fieldName') FieldName: string[] = ['Name', 'Id'];

	@ViewChild('popover') popover;

	@Output('callback') callback: EventEmitter<any> = new EventEmitter<any>();

	@Input('searchText') onSearch: Function;

	public IsOpen: boolean = false;

	constructor() {

		if (Object.keys(this.FieldValue).length == 0)
			this.FieldValue[this.FieldName[0]] = null;
	}

	ngOnInit() {

		// console.log(this.Data);

		// console.log(this.FieldValue);

		// limpar dados
		if (this.onSearch && !this.FieldValue)
			this.Data = [];

	}

	ngOnChanges(e) {

		// console.log(e, this.Data);

		// console.log(this.FieldValue);



	}

	async OnScroll(e) {

		// if (this.cdkVirtualScroll) {
		// 	this.cdkVirtualScroll.checkViewportSize();
		// }
	}

	OnSearch(ev: any, value: string= '') {

		if (this.onSearch)
			this.onSearch(value)
	}

	public async OnPresentPopover(e: Event) {

		if (this.IsDisabled) return;

		this.popover.event = e;
		this.IsOpen = true;

		await AsyncUtils.Sleep(100);

		if (this.cdkVirtualScroll) {
			this.cdkVirtualScroll.checkViewportSize();
		}

	}

	OnChangeValue(item) {

		if (this.IsDisabled) return;

		console.log(item?.detail?.value);

		const value = item?.detail?.value;

		this.FieldValue = value;

		this.IsOpen = false;

		if (this.callback)
			this.callback.emit(this.FieldValue);

	}

	trackByFn(index, item) {
		return index;
	}
}
