import { IDashboardData } from '@models/Dashboard';
import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'chart-simple',
	templateUrl: './simple.component.html',
	styleUrls: ['./simple.component.scss'],
})
export class SimpleChartComponent implements OnInit {

	@Input('data') Data: IDashboardData;

	@Input('height') Height: string = "400";

	constructor() { }

	ngOnInit() {

		console.log(this.Data);
		

		this.normalizeData();
	}


	private async normalizeData(data: any = this.Data) {

		const datasetLabels = data?.labels || '';
		const dataset = data?.datasets[0].data || null;
		const description = data?.datasets[0].label || null;


	}

}
