import { ProjectService } from '@services/project.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ENUM_MAP_ADDRESS_TYPE } from '@components/forms/address-google-map/address-google-map.component';
import { ModalController, NavParams } from '@ionic/angular';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';
import { FormValidators } from '@validators/form.validators';

@Component({
	selector: 'app-door-number',
	templateUrl: './door-number.component.html',
	styleUrls: ['./door-number.component.scss'],
})
export class DoorNumberModalComponent implements OnInit {

	public Form: FormGroup;

	public MapOptions: google.maps.MapOptions;

	private data: any;
	private onSave: Function;
	private showToast: Function;
	private showAlert: Function;

	constructor(
		private ModalController: ModalController,
		private ProjectService: ProjectService,
		private NavParams: NavParams) {

		const projectSettings = this.ProjectService.GetSettings();


		this.MapOptions = {
			zoom: 16,
			// mapTypeId: "styled_map",
			// mapTypeId: 'hybrid',
			mapTypeId: '',
			disableDefaultUI: true,
			clickableIcons: false,
			panControl: false,
			center: { lat: Number(projectSettings.Coordinates.Latitude), lng: Number(projectSettings?.Coordinates.Longitude) },
			gestureHandling: 'none' // disable gestures
		};

		this.data = this.NavParams.get('data') || null;
		this.onSave = this.NavParams.get('onSave') || null;
		this.showToast = this.NavParams.get('showToast') || null;
		this.showAlert = this.NavParams.get('showAlert') || null;

		console.log(this.data);


		this.createFormElements();
	}

	ngOnInit() { }

	private createFormElements(data: any = this.data) {

		this.Form = new FormGroup({
			Id: new FormControl(data?.Id || null, []),
			Number: new FormControl(data?.Number || null, [Validators.required]),
			Observations: new FormControl(data?.Observations || null, []),
			Location: new FormGroup({
				Id: new FormControl(data?.Location?.Id || null),
				Name: new FormControl(data?.Location?.Name || null),
				Coordinates: new FormGroup({
					Latitude: new FormControl(data?.Location?.Coordinates?.Latitude || ''),
					Longitude: new FormControl(data?.Location?.Coordinates?.Longitude || ''),
				}),
				Address: new FormControl(data?.Location?.Address || null, []),
				ZipCode: new FormControl(data?.Location?.ZipCode || null, [FormValidators.ZipCodeValidator]),
				District: new FormControl(data?.Location?.District || null),
				County: new FormControl(data?.Location?.County || null),
				Locality: new FormControl(data?.Location?.Locality || null, []),
				MapType: new FormControl(data?.IdLocationType || ENUM_MAP_ADDRESS_TYPE.POINT, []),
			})
		})
	}

	Close() {
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data
		this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Close);
	}

	async OnSave(ev?: any) {

		console.log(this.Form);

		if (!this.Form.dirty) return this.showToast('Não foram encontradas alterações.');

		if (!this.Form.valid) {

			this.Form.markAllAsTouched();

			return this.showToast('Preencha todos os campos assinalados.');
		}

		const data = {
			Id: this.Form.get('Id').value || '',
			Number: this.Form.get('Number').value || '',
			IdLocation: null,
			Location: this.Form.get('Location').value || null,
			Observations: this.Form.get('Observations').value || ''
		};


		if (this.onSave) {

			const response = await this.onSave(data);

			if (response) this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Save);

		}

	}

}
