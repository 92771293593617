import { IFilterList } from './../models/Content';
import { Injectable } from '@angular/core';
import { WebService } from './web.service';

@Injectable({
	providedIn: 'root'
})
export class UsersService {

	private UserRoles: any[];

	constructor(
		private WebService: WebService
	) { }


	/**
	 * 
	 * @param filters 
	 * @returns 
	 */
	public async GetUsersList(filters: IFilterList) {
		const response = await this.WebService.GetUsersList(filters);

		return response;
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	public async GetUserById(id: number) {
		const response = await this.WebService.GetUsersById(id);

		return response;
	}

	/**
	 * GET LIST OF ROLES FOR USERS
	 * TECHNICIAN, USER, WORKER, etc
	 * @returns 
	 */
	public async GetRoleUsersList(forced: boolean = false) {

		if (this.UserRoles?.length > 0 && !forced) return Promise.resolve(this.UserRoles);

		const response = await this.WebService.GetRoleUsersList();

		this.UserRoles = response?.Result || [];

		return this.UserRoles;
	}

	/**
	 * 
	 * @param idUserRole 
	 * @returns 
	 */
	public async GetRoleUserPermissionListTemplate(idUserRole) {

		const response = await this.WebService.GetRoleUserPermissionListTemplate(idUserRole);

		return response;
	}


	/**
	 * 
	 * @param data 
	 * @returns 
	 */
	public async SaveRoleUserPermissionListTemplate(data) {

		const response = await this.WebService.SaveRoleUserPermissionListTemplate(data);

		return response;
	}


	/**
	 * guardar dados utilizador
	 * @param user 
	 * @returns 
	 */
	public async SaveUser(user) {

		const response = await this.WebService.SaveUser(user);

		return response;
	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	public async DeleteUser(id: number) {

		const response = await this.WebService.DeleteUser(id);

		return response;
	}

	/**
	 * 
	 * @param userIdList 
	 * @returns 
	 */
	public async UserGenerateNewPassword(userIdList: string[]) {

		const response = await this.WebService.UserGenerateNewPassword(userIdList);

		return response;
	}

	/**
	 * 
	 * @param userIdList 
	 * @returns 
	 */
	public async UserSendEmailToDefinePassword(userIdList: string[]) {

		const response = await this.WebService.UserSendEmailToDefinePassword(userIdList);

		return response;
	}
}
