<ion-grid class="table">
	<ion-row class="table-header">
		<ion-col class="flex-align-center" i18n=":@@manage-files.designation">Designação </ion-col>
		<ion-col class="flex-align-center" i18n=":@@manage-files.doc-name">Doc. </ion-col>
		<ion-col class="flex-align-center" style="max-width: 120px;" i18n=":@@manage-files.vat-number">NIF </ion-col>
		<ion-col class="flex-align-center" i18n=":@@manage-files.doc-type">Tipo</ion-col>
		<ion-col class="flex-align-center" style="max-width: 120px;" i18n=":@@manage-files.value">Valor </ion-col>
		<ion-col class="buttons-size-2"></ion-col>
	</ion-row>

	<ion-row *ngFor="let item of Data" class="table-body with-button-opacity">
		<ion-col class="flex-align-center">
			{{item?.Description||'--'}}
		</ion-col>
		<ion-col class="flex-align-center">
			<p [title]="item?.File?.OriginalName">{{item?.File?.OriginalName}}</p>
		</ion-col>
		<ion-col class="flex-align-center" style="max-width: 120px;">
			{{item?.VatNumber || '--'}}
		</ion-col>
		<ion-col class="flex-align-center">
			{{item?.AgreementType?.Name||'--'}}
		</ion-col>
		<ion-col class="flex-align-center" style="max-width: 120px;">
			{{(item?.AssignedValue|currency:'EUR')||'--'}}
		</ion-col>
		<ion-col class="flex-justify-end buttons-size-2">
			<ion-button title="eliminar documento" fill="clear" size="small" color="danger"
				[actionsPermissions]="EnumActionsPermissions.FINANCIALDELETEDOCUMENTS" (click)="OnDelete($event, item)">
				<ion-icon name="trash-outline" slot="icon-only"></ion-icon>
			</ion-button>
			<ion-button title="visualizar documento" fill="clear" size="small" (click)="OnView($event, item)">
				<ion-icon name="open-outline" slot="icon-only"></ion-icon>
			</ion-button>

		</ion-col>
	</ion-row>
</ion-grid>