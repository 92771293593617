export enum EnumAppMenu {
    Initialize = "initialize",

    Error = "error",
    Login = "login",
    Recovery = "recover",
    Dashboard = "dashboard",
    Notifications = "notifications",
    Settings = "settings",
    Account = "account",


    News = "news",
    NewsAdd = "news/list/add",
    NewsEdit = "news/list/edit",
    NewsView = "news/list/view",

    Parish = "parishes/list",
    ParishAdd = "parishes/list/add",
    ParishEdit = "parishes/list/edit",
    ParishView = "parishes/list/view",

    PublicSpaces = "public-spaces",
    PublicSpacesAdd = "public-spaces/add",
    PublicSpacesEdit = "public-spaces/edit",
    PublicSpacesView = "public-spaces/view",

    Protocols = "protocols",
    ProtocolsAdd = "protocols/list/add",
    ProtocolsEdit = "protocols/list/edit",
    ProtocolsView = "protocols/list/view",
    ProtocolsList = "protocols/list",

    ProtocolsSubCategoryAdd = "protocols/list/:protocol/add",
    ProtocolsSubCategoryEdit = "protocols/list/:protocol/edit",
    ProtocolsSubCategoryView = "protocols/list/:protocol/view",

    Configurations = "configurations",
    ConfigurationsMenuView = "configurations/general/menu/view",
    ConfigurationsMenuEdit = "configurations/general/menu/edit",
    ConfigurationsEditionView = "configurations/general/edition/view",
    ConfigurationsEditionEdit = "configurations/general/edition/edit",
    ConfigurationsMenuAdd = "configurations/general/menu/add",
    ConfigurationsEditionAdd = "configurations/general/edition/add",
    ConfigurationsPermissionAdd = "configurations/general/permission/add",
    ConfigurationsPermissionView = "configurations/general/permission/view",
    ConfigurationsPermissionEdit = "configurations/general/permission/edit",

    FinancialIntervention = "management",
    FinancialInterventionList = "management/:context/list",
    FinancialInterventionParishList = "management/:context/list/:parish",
    FinancialInterventionAdd = "management/:context/list/:parish/add",
    FinancialInterventionView = "management/:context/list/:parish/view",
    FinancialInterventionEdit = "management/:context/list/:parish/edit",


    FinancialBudgetList = "management/budget/list",
    FinancialBudgetListParish = "management/budget/list/:parish",
    FinancialBudgetAdd = "management/budget/list/:parish/add",
    FinancialBudgetView = "management/budget/list/:parish/view",
    FinancialBudgetEdit = "management/budget/list/:parish/edit",


    UsersList = "users/list",
    UsersAdd = "users/add",
    UsersView = "users/view",
    UsersEdit = "users/edit",

    InternalExpenses = "internal-expenses",
    InternalExpensesAdd = "internal-expenses/list/add",
    InternalExpensesEdit = "internal-expenses/list/edit",
    InternalExpensesView = "internal-expenses/list/view",
    InternalExpensesList = "internal-expenses/list",

}

export interface IAppMenu {
    Title: string,
    Url: string,
    Icon: string
}

export interface IPlatformMenusModel {
    Menus: IMenusModel[],
    Platform: any
}


export enum CONFIGURATIONSTABSEGMENTSIDS {
    EDITION = 1,
    MENU = 2,
    PERMISSIONS = 3
}

export enum EnumMenuType {
    SIDE = "1",
    STATIC = "2"
}


export interface IAppMenu {
    Title: string,
    Url: string,
    Icon: string
}

export interface IMenusModel {

    Id: number;
    IdEntity?: string;
    IdScope?: number;
    IdPlatform: number;
    Name?: string;
    IdParent?: number;
    IsSingleData?: boolean;
    NavigationPath?: string;
    NavigationIcon?: string;
    NavigationPosition?: number;
    NavigationBackgroundColor?: string;
    NavigationTextColor?: string;
    IsTabNavigation?: boolean;
    IsPublic?: boolean;
    Scope?: IMenusScopeModel,
    Parent?: IMenusModel,
    Children?: IMenusModel[],
    CreateDate?: string;
    PermittedScopeOperationList?: number[];
    IdContentType?: string;
    IdType?: string;

    Position?: number;
    QueryParams?: any;

    tempPosition?: any;
}

export interface IMenusScopeModel {
    Id: number;
    IdParent: number;
    ShowOnDashboard: boolean;
    ForDataManagement: boolean;
    Name: string;
    OperationList: number[];
    CreateDate: string;
}
export interface IPlatformMenusModel {
    Menus: IMenusModel[],
    Platform: any
}

export interface IMenuTabModel extends IMenusModel {
    IdTab: number;
}

export class MenuTabModel implements IMenuTabModel {

    Id: number;
    IdPlatform: number;
    IsTabNavigation?: boolean;
    Scope?: IMenusScopeModel;
    NavigationPath?: string;
    NavigationPosition?: number;
    Name?: string;

    IdTab: number;

    constructor(data: Partial<IMenuTabModel> = {}) {

        this.Id = data?.Id || null;
        this.IdPlatform = data?.IdPlatform || null;
        this.IsTabNavigation = data?.IsTabNavigation || null;
        this.Scope = data?.Scope || null;
        this.NavigationPath = data?.NavigationPath ? data?.NavigationPath[0] != "/" ? ("/" + data?.NavigationPath) : data?.NavigationPath : '/';
        this.NavigationPosition = data?.NavigationPosition || null;
        this.Name = data?.Name || '';

        console.log(this.NavigationPath);


        if (this.NavigationPath) {
            const statecopy = new URL(window.location.origin + this.NavigationPath.slice(0, this.NavigationPath.length));
            const queryparams = Object.fromEntries(statecopy.searchParams as any);

            this.IdTab = queryparams.id ? parseInt(queryparams.id) : null;
        }
        else
            this.IdTab = null;

    }
}