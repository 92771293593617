import { EnumActionsPermissions } from '@models/EnumPermissions';
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'manage-files',
	templateUrl: './manage-files.component.html',
	styleUrls: ['./manage-files.component.scss'],
})
export class ManageFilesComponent implements OnInit {


	@Input('data') Data: any[] = [];
	@Input('onDelete') onDelete: Function;

	public EnumActionsPermissions = EnumActionsPermissions;

	constructor() { }

	ngOnInit() {
		console.log(this.Data);

	}

	/**
	 * 
	 * @param ev 
	 * @param item 
	 * @returns 
	 */
	OnView(ev: any, item: any) {

		if (!item?.File?.Path) return;

		window.open(item?.File?.Path, '_blank')

	}

	/**
	 * 
	 * @param ev 
	 * @param item 
	 */
	async OnDelete(ev: any, item: any) {

		console.log(item);

		if (this.onDelete) {
			const response = await this.onDelete([item?.IdFile]);

			console.log(response);


		}


	}


}
