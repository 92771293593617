export enum EnumActionsPermissions {

    VIEW = 1,
    CREATEANDEDIT = 2,
    DELETE = 3,
    GENERATEUSERCREDENTIALS = 8,
    VIEWPEOPLEUNDERITSGUARDIANSHIP = 10,
    TRANSITYEARBOOKDATA = 50,
    FINANCIALMANAGEMENT = 60,
    FINANCIALSENDDOCUMENTS = 70,
    FINANCIALDELETEDOCUMENTS = 80,
    MANAGEMENUCONFIGURATIONS = 99

}