import { URLUtils } from '@utils/URLUtils';
import { NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { filter } from 'rxjs/operators';
import { EnumAppMenu } from '@models/AppMenus';

@Injectable(
	{
		providedIn: 'root'
	})
export class NavigationHistoryService {
	private history: string[];

	private state: string;

	constructor(private Router: Router) {
		this.history = [];
	}

	/**
	 * Inicia criação de histórico de navegação.
	 * @returns string - url de redirect/boot da aplicação
	 */
	public Init() {
		this.Router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
			//console.log(e.url);

			this.history.push(e.url);
		});

		return this.SetRedirectUrl();
	}

	/**
	 * Retorna lista de histórico.
	 * @returns string[]
	 */
	public GetHistory() {
		return this.history;
	}

	/**
	 * Retorna o URL actual/ultimo na lista de histórico.
	 * @returns 
	 */
	public GetCurrentHistoryState(): string {
		if (this.history) return this.history[this.history.length - 1];

		return null;
	}

	/**
	 * Retorna o URL actual/ultimo na lista de histórico.
	 * @retur   ns 
	 */
	public GetPreviousHistoryState(): string {
		if (this.history) return this.history[this.history.length - 1];

		return null;
	}


	/**
	 * Retorna string com o path completo do state actual composto por window.location.pathname + window.location.search;
	 */
	public GetCurrentState(): string {
		return window.location.pathname + window.location.search;
	}

	/**
	 * Navega para state actual, força a navegação, boot da aplicação no mesmo state.
	 * @param navigateTo url para o qual deve navegar by navigateByUrl
	 * @param redirectTo url para qual será
	 */
	public NavigateAndReload(navigateTo: string, redirectTo: string) {
		let currentUrl = URLUtils.RemoveLanguageReference(this.Router.url);

		this.Router.navigateByUrl(navigateTo, { skipLocationChange: true }).then(() => {
			this.Router.navigate([currentUrl]);
		});
	}

	/**
   * Guarda um URL para redirect a após login ou outras acções a partir do ecrã de login ou do initialize.
   * Caso não seja passado url, usa como default o state actual composto pelos seguintes params: window.location.pathname + window.location.search.
   * @param url url parcial correspondente a um state de navegação
   */
	public SetRedirectUrl(url: string = undefined): boolean {
		if (!url) url = this.GetCurrentState();

		this.state = url;

		return true;
	}

	/**
	 * Verifica se o path de redirect é valido.
	 * Se existir path valido retorna o path, caso contrario retorna null.
	   * Ao retornar um URL de redirect limpa o URL de redirect existente.
	   * Exclui um conjunto de states considerados como inválidos para redirect.
	 */
	public GetRedirectUrl(): string {
		if (this.state
			&& this.state != ""
			&& this.state.indexOf("/initialize") === -1
			&& this.state.indexOf(".html") === -1) {
			if (this.state == "/") return null;

			const statecopy: string = this.state.slice(0, this.state.length);

			this.state = null;

			return URLUtils.RemoveLanguageReference(statecopy);
		}

		return null;
	}

	/**
	 * 
	 * @returns 
	 */
	public GetRedirectUrlAndParams(): { redirect: string, queryParams?: any } {

		if (this.state
			&& this.state != ""
			&& this.state.indexOf(EnumAppMenu.Login) === -1
			&& this.state.indexOf(EnumAppMenu.Initialize) === -1
			&& this.state.indexOf(".html") === -1) {

			if (this.state == "/") return { redirect: null, queryParams: null };

			const statecopy = new URL(window.location.origin + this.state.slice(0, this.state.length));

			console.log("redirect on get url params");

			console.log(statecopy.pathname, this.state);

			const queryparams = Object.fromEntries(statecopy.searchParams as any);

			this.state = null;

			return { redirect: statecopy.pathname, queryParams: queryparams };
		}

		return { redirect: null, queryParams: null };
	}

}
