import { Subscription } from 'rxjs';
import { MenuService } from '@services/menu.service';
import { EnumMenuType, IMenusModel } from '@models/AppMenus';
import { Component, OnInit, OnChanges } from '@angular/core';

@Component({
	selector: 'side-menu-content',
	templateUrl: './content.component.html',
	styleUrls: ['./content.component.scss'],
})
export class SideMenuContentComponent implements OnInit {

	public appPages: IMenusModel[];

	private sub: Subscription;

	constructor(private MenuService: MenuService) { }

	async ngOnInit() {

		this.sub = this.MenuService.Menus.subscribe((menus: IMenusModel[]) => {

			this.appPages = (menus || [])?.filter(it => it?.IdType == EnumMenuType.SIDE);
		})
	}


	public OnSetScopes(menu: IMenusModel) {
		// this.MenuService.SetMenuSelected(menu);
	}

}
