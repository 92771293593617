import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { EnumAppMenu } from '@models/AppMenus';
import { SessionService } from '@services/session.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RestrictAccessGuard {

	constructor(private router: Router, private SessionService: SessionService, private NavController: NavController) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		return this.canForwardRoute(state);
	}

	private async canForwardRoute(state?: RouterStateSnapshot): Promise<boolean> {

		const session = this.SessionService.Get();

		if (this.SessionService.HasSession()) {

			const yearBooks = session?.UserData?.YearBookList || [];

			if (yearBooks?.length == 1) {

				const statecopy = new URL(window.location.origin + state?.url?.slice(0, state?.url?.length));

				const aux = statecopy.pathname.split('/');

				const idContext = aux.length > 2 && Number.isInteger(Number(aux[2])) ? Number(aux[2]) : null;

				/**
				 * VERIFY AND ENTER THE CORRECT PATH FOR THE YEARBOOK DATA
				 */
				switch (statecopy?.pathname) {
					case ("/" + EnumAppMenu.Parish):
						this.NavController.navigateRoot([EnumAppMenu.ParishView, yearBooks[0]?.Id], { replaceUrl: true });
						break;

					case ("/" + EnumAppMenu.FinancialBudgetList):
						this.NavController.navigateRoot([EnumAppMenu.FinancialBudgetListParish.replace(':parish', ''), yearBooks[0]?.Id], { replaceUrl: true });
						break;

					case ("/" + EnumAppMenu.FinancialInterventionList.replace(':context', String(idContext))):
						this.NavController.navigateRoot([EnumAppMenu.FinancialInterventionParishList.replace(':context', String(idContext)).replace(':parish', ''), yearBooks[0]?.Id], { replaceUrl: true });
						break;

					default:
						break;
				}


			}
		}

		return;

	}
}

export const RestrictGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	return inject(RestrictAccessGuard).canActivate(next, state);
}
