<ion-header>
	<ion-toolbar class="ion-padding-end">
		<!-- <ion-title>Documentos</ion-title> -->

		<ion-button style="margin-right: -16px;" fill="clear" color="dark" shape="round" slot="end" (click)="Close()">
			<ion-icon slot="icon-only" name="close-outline"></ion-icon>
		</ion-button>

	</ion-toolbar>
</ion-header>
<ion-content class="ion-padding padding-horizontal">
	<form [formGroup]="Form">
		<ion-grid>
			<ion-row>
				<ion-col>
					<ion-row>
						<!-- <ion-col>
							<ion-item class="full" lines="full" fill="outline">
								<ion-label position="stacked" i18n=":@@modal-door-number.designation">Designação</ion-label>
								<ion-input formControlName="Des" placeholder="ex.: 123A"></ion-input>
							</ion-item>
						</ion-col> -->
						<ion-col size="6" sizeMd="2">
							<ion-item class="full" lines="full" fill="outline">
								<ion-label position="stacked" i18n=":@@modal-door-number.numberdoor" required>Número de
									Porta</ion-label>
								<ion-input formControlName="Number" placeholder="ex.: 123A"></ion-input>
							</ion-item>
						</ion-col>
					</ion-row>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col>
					<address-google-map label="Morada" [form]="Form.get('Location')" mapId="doornumber" class="small"
						[mapOptions]="MapOptions" [size]="'small'" [typeOptions]="[]"></address-google-map>

				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col>
					<ion-item class="full" lines="full" fill="outline">
						<ion-label position="stacked" i18n=":@@modal-door-number.observations">Observações</ion-label>
						<ion-textarea rows="4" formControlName="Observations"
							placeholder="Adicione o seu texto aqui..."></ion-textarea>
					</ion-item>
				</ion-col>
			</ion-row>
		</ion-grid>
	</form>
</ion-content>
<ion-footer>
	<ion-toolbar class="ion-padding-horizontal">
		<ion-button style="margin-right: -16px;" slot="end" fill="solid" color="medium" (click)="OnSave()"
			i18n=":@modal-door-number.save">Guardar
		</ion-button>
	</ion-toolbar>
</ion-footer>