import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

export enum EnumStorageKeys
{
    Language = "smartcouncil.language",
    Session = "smartcouncil.session",
    Preferences = "smartcouncil.preferences",
    Edtion = 'smartcouncil.edition'
}

@Injectable(
{
    providedIn: 'root'
})
export class StorageService
{
    private storage: Storage | null = null;

    constructor(private Storage: Storage) 
    {

    }

    /**
     * Inicializa storage.
     */
    async Init()
    {
        this.storage = await this.Storage.create();
    }

    /**
     * Guarda em local storage um conjunto de dados para uma determinada key.
     * Caso guarde com sucesso os dados devolve os proprios dados, caso contrário devolve null.
     * @param key valor enum EnumStorageKeys
     * @param data any valor a ser guardado
     * @returns 
     */
    async Save(key:EnumStorageKeys, data:any)
    {
        try 
        {
            await this.storage?.set(key, JSON.stringify(data));

            return data;
        } 
        catch (error) 
        { 
            return null;
        }
    }

    /**
     * 
     * @param key 
     * @returns 
     */
    async Delete(key:EnumStorageKeys)
    {
        try 
        {
            await this.storage?.remove(key);

            return null;
        } 
        catch (error) 
        { 
            return null;
        }
    }

    /**
     * Efectua leitura em local storage de uma determinada key e devolve o seu valor.
     * Caso a key não existe ou seja possivel efectuar o parse de dados devolve null.
     * @param key valor enum EnumStorageKeys
     * @returns 
     */
    async Get(key:EnumStorageKeys)
    {
        try
        {
            const value = await this.storage?.get(key);

            return JSON.parse(value);
        } 
        catch (error) 
        { 
            return null;
        }
    }
}
