import { Subscription } from 'rxjs';
import { IDashboardData } from '@models/Dashboard';
import { ChartJSService } from '@services/chartjs.service';
import { AfterContentInit, Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { AsyncUtils } from '@utils/AsyncUtils';
import { outputAst } from '@angular/compiler';

@Component({
	selector: 'bar-chart',
	templateUrl: './bar-chart.component.html',
	styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input('height') Height: string = "400";

	@ViewChild('canvasChart') canvasChart: ElementRef<HTMLCanvasElement>;

	@Input() data: IDashboardData;

	private chart: any;

	constructor(private ChartJSService: ChartJSService) { }

	ngOnInit() {
		console.log(this.Height);


		// this.normalizeData();
	}

	ngOnDestroy(): void {

		if (this.chart)
			this.chart.destroy();

		console.log("destroy ->", this.chart);

	}

	ngAfterViewInit(): void {

		this.normalizeData();
	}


	private async normalizeData(data: any = this.data) {
		
		// test 2 or more datasets
		// const tmp = [...data?.datasets, ...data?.datasets];
		
		this.chart = await this.ChartJSService.GetBarChart(this.canvasChart.nativeElement, data?.labels, data?.datasets);


	}

}
