<ion-grid class="full">
	<ion-row>
		<ion-col size="2"></ion-col>
		<ion-col size="8" class="image-cropper-render ion-justify-content-center ion-align-items-center"
			style="display: flex;">

			<img *ngIf="data" [src]="data" style="cursor: pointer;" alt="imagem" (click)="OnViewImage($event)" />
			<img *ngIf="!data" style="opacity: 0.4;" [src]="DefaultImage" alt="imagem" />

		</ion-col>
		<ion-col size="2" class="ion-justify-content-end ion-align-items-end" style="display: flex;">
			<ng-container *ngIf="!disabled">

				<ion-button color="danger" *ngIf="data" (click)="OnDeleteImage()" title="remover imagem">
					<ion-icon slot="icon-only" name="trash-outline"></ion-icon>
					<!-- <ng-container>Remover imagem</ng-container> -->
				</ion-button>

				<ion-button color="medium" (click)="OnPresentModal()" title="escolher imagem">
					<ion-icon slot="icon-only" name="image-outline"></ion-icon>
					<!-- <ng-container>Escolher imagem</ng-container> -->
				</ion-button>
			</ng-container>
		</ion-col>
	</ion-row>
</ion-grid>