import { SessionService } from './services/session.service';
import { StorageService } from '@services/storage.service';
import { Component, OnInit } from '@angular/core';

import { NavController, MenuController } from '@ionic/angular';
import { NavigationHistoryService } from '@services/navigation-history.service';

import dayjs from 'dayjs';

import 'dayjs/locale/pt';

import customParseFormat from 'dayjs/plugin/customParseFormat';

import { ProjectService } from '@services/project.service';
import { IProjectSettings } from '@models/ProjectSettings';
import { ThemeService } from '@services/theme-css.service';
import { SEOService } from '@services/seo.service';
import { EnumAppMenu } from '@models/AppMenus';

@Component(
	{
		selector: 'app-root',
		templateUrl: 'app.component.html',
		styleUrls: ['app.component.scss'],
	})
export class AppComponent implements OnInit {

	public ProjectSettings: IProjectSettings;

	constructor(private NavController: NavController,
		private NavigationHistoryService: NavigationHistoryService,
		private SessionService: SessionService,
		private StorageService: StorageService,
		private ProjectService: ProjectService,
		private ThemeService: ThemeService,
		private SEOService: SEOService,
		private MenuController: MenuController) {

	}

	async ngOnInit() {

		this.MenuController.enable(false);

		await this.NavigationHistoryService.Init();

		this.ProjectSettings = await this.ProjectService.Init();

		const projectPath = this.ProjectService.GetProjectPath();

		await this.ThemeService.Init();

		this.ThemeService.setTheme(projectPath);

		await this.StorageService.Init();

		await this.SessionService.Init();

		// this.SEOService.SetupGoogleAnalytics(this.ProjectSettings);

		console.log(this.ProjectSettings);
		
		this.SEOService.UpdateProjectMetadata(this.ProjectSettings);

		dayjs.locale('pt');
		dayjs.extend(customParseFormat);

		this.NavController.navigateRoot([EnumAppMenu.Initialize], { animated: false });

	}


}
