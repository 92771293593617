import { CanLeavePageGuard } from './guards/can-leave-page.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { RestrictGuard } from '@guards/restrict-access.guard';

const routes: Routes =
	[
		{
			path: 'login',
			loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
		},
		{
			path: 'initialize',
			loadChildren: () => import('./pages/initialize/initialize.module').then(m => m.InitializePageModule)
		},
		{
			path: 'dashboard',
			canActivate: [AuthGuard],
			loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
		},
		{
			path: 'news',
			canActivate: [AuthGuard],
			canActivateChild: [AuthGuard],
			loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule)
		},
		{
			path: 'settings',
			canActivate: [AuthGuard],
			loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
		},
		{
			path: 'configurations',
			canActivate: [AuthGuard],
			canActivateChild: [AuthGuard],
			loadChildren: () => import('./pages/configurations/configurations.module').then(m => m.ConfigurationsPageModule)
		},
		{
			path: 'users',
			canActivate: [AuthGuard],
			canActivateChild: [AuthGuard],
			loadChildren: () => import('./pages/users/users.module').then(m => m.UsersPageModule)
		},
		{
			path: 'account',
			canActivate: [AuthGuard],
			loadChildren: () => import('./pages/account/account.module').then(m => m.AccountPageModule),
		},
		{
			path: 'public-spaces',
			canActivate: [AuthGuard],
			loadChildren: () => import('./pages/public-spaces/public-spaces.module').then(m => m.PublicSpacesPageModule)
		},
		{
			path: 'parishes',
			canActivate: [AuthGuard, RestrictGuard],
			canActivateChild: [AuthGuard, RestrictGuard],
			loadChildren: () => import('./pages/parishes/parishes.module').then(m => m.ParishesPageModule)
		},
		{
			path: 'protocols',
			canActivate: [AuthGuard],
			loadChildren: () => import('./pages/protocols/protocols.module').then(m => m.ProtocolsPageModule)
		},
		{
			path: 'management',
			canActivate: [AuthGuard, RestrictGuard],
			canActivateChild: [AuthGuard, RestrictGuard],
			loadChildren: () => import('./pages/financial-management/financial-management.module').then(m => m.FinancialManagementPageModule)
		},
		{
			path: 'internal-expenses',
			canActivate: [AuthGuard],
			loadChildren: () => import('./pages/internal-expenses/internal-expenses.module').then(m => m.InternalExpensesPageModule)
		},
		{
			path: 'notifications',
			canActivate: [AuthGuard],
			loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule)
		},
		{
			path: 'recover',
			loadChildren: () => import('./pages/recovery-password/recovery-password.module').then(m => m.RecoveryPasswordPageModule)
		},
		{
			path: 'error',
			loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorPageModule)
		},
		{
			path: '**',
			redirectTo: '/error',
			data: {}
		},
		
	];


@NgModule(
	{
		imports:
			[
				RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
			],
		providers: [CanLeavePageGuard],
		exports: [RouterModule]
	})
export class AppRoutingModule { }