import { EnumActionsPermissions } from '@models/EnumPermissions';
import { EnumAppMenu, IPlatformMenusModel, IMenusModel } from '@models/AppMenus';
import { IonReorderGroup, ItemReorderEventDetail, NavController } from '@ionic/angular';
import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

@Component({
	selector: 'configurations-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuConfigurationsComponent implements OnInit, OnChanges {

	@Input('reorder') Reorder: boolean = false;
	@Input('data') MenusList: IMenusModel[];
	// private menusListCopy: IMenusModel[] = [];

	@Input('platforms') Platforms: any[];

	@Input('delete') Delete: Function;

	public Data: IPlatformMenusModel[] = [];

	@ViewChild(IonReorderGroup) ReorderGroup: IonReorderGroup;

	public EnumActionsPermissions = EnumActionsPermissions;

	constructor(private NavController: NavController) { }

	ngOnInit() {

		console.log(this.MenusList, this.Platforms);

		this.loadData();

	}

	ngOnChanges(changes: SimpleChanges): void {


		console.log(changes, this.Data);

		this.loadData();

	}

	private async loadData() {

		this.Data = [];

		this.MenusList.forEach((el, i) => {
			el['NavigationPosition'] = i;
			el['Position'] = i;
		});

		// this.menusListCopy = Object.assign([], this.MenusList);

		this.Platforms.forEach((platform: any) => {

			const menus = this.MenusList.filter(it => platform.Id == it?.IdPlatform);
			// .filter(it => !it.IdParent)
			if (menus?.length > 0)
				this.Data.push({ Platform: platform, Menus: menus })
		});
	}

	OnChangePage(e) {

	}

	OnView(item: any) {

		this.NavController.navigateForward([EnumAppMenu.ConfigurationsMenuView, item?.Id])
	}

	OnEdit(item: any) {

		this.NavController.navigateForward([EnumAppMenu.ConfigurationsMenuEdit, item?.Id])
	}

	async OnDelete(item: any) {

		if (!this.Delete) return;

		const response = await this.Delete(item);

		console.log(response);

	}

	OnReorder(ev: any | CustomEvent<ItemReorderEventDetail>, menus: any[]) {
		// The `from` and `to` properties contain the index of the item
		// when the drag started and ended, respectively
		console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);

		let movedItem = menus.splice(ev.detail.from, 1)[0];
		menus.splice(ev.detail.to, 0, movedItem)

		// // Finish the reorder and position the item in the DOM based on
		// // where the gesture ended. This method can also be called directly
		// // by the reorder group
		ev.detail.complete();
		console.log(this.Data, this.MenusList);

		this.normalizeData();
	}

	private normalizeData() {

		let count = 0;
		this.Data.forEach(el => {
			el.Menus.forEach(m => {
				if (this.MenusList.find(i => i.Id == m.Id))
					this.MenusList.find(i => i.Id == m.Id).Position = count;

				++count;
			})
		})
	}

	OnReorderChild(ev: any | CustomEvent<ItemReorderEventDetail>) {
		// The `from` and `to` properties contain the index of the item
		// when the drag started and ended, respectively
		console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);
	}

	toggleReorderGroup() {
		this.ReorderGroup.disabled = !this.ReorderGroup.disabled;
	}

}
