import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { DoorNumberModalComponent } from '@modals/door-number/door-number.component';

@Component({
	selector: 'card-door-number',
	templateUrl: './door-number.component.html',
	styleUrls: ['./door-number.component.scss'],
})
export class DoorNumberFormComponent implements OnInit {

	@Input('idAsset') IdAsset: string;
	// @Input('number') Number: number;
	// @Input('idLocation') IdLocation: number;
	// @Input('observations') Observations: string;
	@Input('source') Data: any[];
	@Input('disabled') IsDisabled: boolean = true;
	@Input('save') SaveAssetDoorNumber: Function;
	@Input('delete') DeleteAssetDoorNumber: Function;
	@Input('showToast') ShowToast: Function;
	@Input('showAlert') ShowAlert: Function;

	constructor(private ModalController: ModalController) { }

	ngOnInit() { }

	public async OnAddNewDoorNumber() {

		if (this.IsDisabled) return;

		const modal = await this.ModalController.create({
			component: DoorNumberModalComponent,
			componentProps: {
				data: null,
				onSave: (data) => this.SaveAssetDoorNumber(data),
				showToast: (message) => this.ShowToast(message),
				showAlert: (header, message) => this.ShowAlert(header, message)
			},
			cssClass: 'default'
		});

		await modal.present();

		const { role, data } = await modal.onDidDismiss();
	}

	async OnView(item) {

		console.log(item);

		const modal = await this.ModalController.create({
			component: DoorNumberModalComponent,
			componentProps: {
				data: item,
				onSave: (data) => this.SaveAssetDoorNumber(data),
				showToast: (message) => this.ShowToast(message),
				showAlert: (header, message) => this.ShowAlert(header, message)
			},
			cssClass: 'default'
		});

		await modal.present();

		const { role, data } = await modal.onDidDismiss();
	}

	async OnDelete(id) {

		console.log(id);

		if (this.DeleteAssetDoorNumber)
			this.DeleteAssetDoorNumber(id);
	}

}
