<ion-card class="ion-no-padding">
	<ion-card-header>
		<ion-card-title class="ion-justify-content-center display-flex">Orçamento</ion-card-title>
	</ion-card-header>
	<ion-card-content>
		<ion-grid>
			<ion-row>
				<ion-col class="ion-no-padding">
					<!-- <ion-label>Total</ion-label> -->
				</ion-col>
				<ion-col class="ion-no-padding display-flex ion-justify-content-end">
					<ion-label class="cursor-help" title="Total">
						<strong>{{Total||0|currency:'EUR'}}</strong></ion-label>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col class="ion-no-padding">
					<!-- <ion-label>Utilizado</ion-label> -->
				</ion-col>
				<ion-col class="ion-no-padding display-flex ion-justify-content-end">
					<ion-label class="cursor-help" title="Cativo/Realizado">
						<strong>
							{{Captive||0|currency:'EUR'}} /
							{{Spend||0|currency:'EUR'}}</strong>
					</ion-label>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col class="ion-no-padding">
					<!-- <ion-label>Disponível</ion-label> -->
				</ion-col>
				<ion-col class="ion-no-padding display-flex ion-justify-content-end">
					<ion-label class="cursor-help" title="Disponível"
						style="font-size: 22px;"><strong>{{Available||0|currency:'EUR'}}</strong></ion-label>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-card-content>

</ion-card>