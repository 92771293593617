import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-status',
  templateUrl: './icon-status.component.html',
  styleUrls: ['./icon-status.component.scss'],
})
export class IconStatusComponent implements OnInit {

  @Input('icon') Icon: string = "ellipse-sharp";
  @Input('color') Color: string = "success";
  @Input('title') Title: string = "";

  constructor() { }

  ngOnInit() { }

}
