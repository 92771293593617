<ion-input type="text" class="full outline" [disabled]="IsDisabled"
	[value]="(FieldValue && FieldName[0]) ? FieldValue[FieldName[0]] : ''" (click)="OnPresentPopover($event)" readonly
	[placeholder]="Placeholder" />

<ion-popover #popover [isOpen]="IsOpen" (didDismiss)="IsOpen = false" class="select-search">
	<ng-template>
		<ion-header>
			<ion-toolbar>
				<ion-grid>
					<ion-row>
						<ion-item class="full">
							<ion-searchbar #search class="outline ion-no-margin" placeholder="pesquisar ex.: nº ou nome"
								(ionClear)="OnSearch($event)"
								(keyup.enter)="OnSearch($event, search?.value)"></ion-searchbar>
						</ion-item>
					</ion-row>
				</ion-grid>
			</ion-toolbar>
		</ion-header>
		<ion-content [scrollY]="false" class="ion-no-padding">
			<cdk-virtual-scroll-viewport (scrolledIndexChange)="OnScroll($event)"
				class="popover ion-content-scroll-host" itemSize="48" minBufferPx="450" maxBufferPx="1360">

				<ion-grid class="ion-padding-horizontal"
					*ngIf="(Data | FilterByFields: (search?.value||''): FieldName) as data">
					<ion-row>

						<ion-radio-group class="full" [value]="FieldValue" (ionChange)="OnChangeValue($event)">
							<ng-container
								*cdkVirtualFor="let item of data; let itemBounds = bounds; trackBy: trackByFn">

								<ion-item class="full" style="margin: 0; max-height: 48px;">
									<ion-radio [value]="item" slot="start"></ion-radio>
									<ion-label>{{ (item?.Id ? "#" + item?.Id + " - ": '') +
										(item[FieldName[0]]||'--')}}</ion-label>
								</ion-item>

							</ng-container>
						</ion-radio-group>

					</ion-row>

					<no-items [description]="'sem resultados'" *ngIf="(data)?.length==0"></no-items>

				</ion-grid>

			</cdk-virtual-scroll-viewport>
		</ion-content>
	</ng-template>
</ion-popover>