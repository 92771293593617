import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { AsyncUtils } from '@utils/AsyncUtils';

import * as Quill from 'quill';


@Component({
	selector: 'text-editor-quill',
	templateUrl: './text-editor-quill.component.html',
	styleUrls: ['./text-editor-quill.component.scss'],
})
export class TextEditorQuillComponent implements OnInit, OnChanges {

	@Input('source') Source: any;
	@Input('disabled') Disabled: boolean;
	@Output() callback: EventEmitter<any> = new EventEmitter();

	@Input('custom') customFeatures: boolean = false;

	public QuillId: string;
	public QuillEditor;
	private cursorPosition;

	constructor() {

		this.QuillId = 'quill_' + (Math.random() + 1).toString(36).substring(7);
	}

	async ngOnInit() {
		await this.initQuill();

	}

	async ngOnChanges() {

		await this.initQuill();

		if (!this.QuillEditor) return;

		this.QuillEditor.enable(true);

		if (this.Source?.charAt(0) != '<')
			this.QuillEditor.setText(this.Source || '');
		else
			this.QuillEditor.root.innerHTML = this.Source;

		await AsyncUtils.Sleep(1);

		console.log("quill editor disabled ------->", this.Disabled);


		if (this.Disabled)
			this.QuillEditor.enable(false);

	}

	private async initQuill() {

		//DELAY DE CARREGAMENTO
		await AsyncUtils.Sleep(1)

		if (this.QuillEditor) return;

		const txtArea = document.createElement('textarea');

		if (!document.getElementById(this.QuillId)) return;

		const icons = Quill.import('ui/icons');
		icons['showHtml'] = '<b>HTML</b>';
		// icons['showTags'] = '<b>TAGS</b>';
		// icons['tag1'] = '<b>TAG 1</b>';
		// icons['tag2'] = '<b>Tag 2</b>';

		let quillOptions = {
			modules: {
				toolbar: [
					[{ header: [1, 2, 3, 4, 5, 6, false] }],
					['bold', 'italic', 'underline', 'strike'],
					// ['image','link'],
					['link'],
					[{ 'color': [] }, { 'background': [] }],
					[{ 'indent': '-1' }, { 'indent': '+1' }],
					// [{ 'script': 'sub' }, { 'script': 'super' }],
					[{ 'list': 'ordered' }, { 'list': 'bullet' }],
					[{ 'align': [] }],
					['clean'],
					// ['code-block'],
					// ['showHtml']
				],
				// imageResize: {
				// 	displaySize: true
				// }, 
				// syntax: true,    
			},
			placeholder: 'adicione o seu texto aqui...',
			theme: 'snow'  // or 'bubble');
		};

		if (this.customFeatures) {

			quillOptions = {
				modules: {
					toolbar: [
						[{ header: [1, 2, 3, 4, 5, 6, false] }],
						['bold', 'italic', 'underline', 'strike'],
						// ['image','link'],
						['link'],
						[{ 'color': [] }, { 'background': [] }],
						[{ 'indent': '-1' }, { 'indent': '+1' }],
						// [{ 'script': 'sub' }, { 'script': 'super' }],
						[{ 'list': 'ordered' }, { 'list': 'bullet' }],
						[{ 'align': [] }],
						['clean'],
						// ['code-block'],
						['showHtml']
					],
					// imageResize: {
					// 	displaySize: true
					// }, 
					// syntax: true,    
				},
				placeholder: 'adicione o texto aqui...',
				theme: 'snow'  // or 'bubble');
			};
		}

		this.QuillEditor = new Quill(`#${this.QuillId}`, quillOptions);

		this.QuillEditor.on('text-change', (delta, oldDelta, source) => {

			txtArea.value = this.QuillEditor.root.innerHTML;

			this.cursorPosition = this.QuillEditor.getSelection();

			// console.log(delta, oldDelta, source);
			if (source == 'api') {
				// console.log("An API call triggered this change.");
			} else if (source == 'user') {
				// console.log("A user action triggered this change.");
				// console.log(txtArea.value);
				if (this.callback)
					this.callback.emit(txtArea.value != "<p><br></p>" ? txtArea.value : null);

				// this.Source = txtArea.value;
			}
		});


		this.QuillEditor.on('selection-change', (range, oldRange, source) => {
			this.cursorPosition = this.QuillEditor.getSelection() || this.cursorPosition;

			// if (range) {
			// 	if (range.length == 0) {
			// 		console.log('User cursor is on', range.index);
			// 	} else {
			// 		// const text = this.QuillEditor.getText(range.index, range.length);
			// 		// console.log('User has highlighted', text);
			// 	}
			// } else {
			// 	console.log('Cursor not in the editor');
			// }
		});

		const htmlEditor = this.QuillEditor.addContainer('ql-custom');
		htmlEditor.style.display = 'none';

		htmlEditor.appendChild(txtArea);

		if (this.customFeatures) {

			const customButtonHTML = document.querySelector('.ql-showHtml');
			// // const customButtonTAGS: any = document.querySelector('.ql-tags');

			customButtonHTML.addEventListener('click', () => {

				if (this.Disabled) return;

				if (txtArea.style.display === '')
					this.QuillEditor.root.innerHTML = txtArea.value;

				htmlEditor.style.display = htmlEditor.style.display === 'none' ? '' : 'none';

			});

			// customButtonTAGS.addEventListener('click', (e) => {
			// 	const v: any = document.querySelector('.ql-picker-item.ql-selected');

			// 	if (!v || !v.dataset?.value) return;

			// 	if (!customButtonTAGS.classList.value.includes('ql-expanded')) {
			// 		this.QuillEditor.insertText(this.cursorPosition?.index || 0, v.dataset?.value);
			// 		// this.QuillEditor.insertText(this.cursorPosition?.index || 0, "#" + v.dataset?.value + "#");
			// 	}
			// 	v.classList.remove('ql-selected');

			// });
		}

		return;
	}

}
