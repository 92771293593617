import { MenuService } from '@services/menu.service';
import { SessionService } from '@services/session.service';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { EnumAppMenu } from '@models/AppMenus';

@Injectable({
	providedIn: 'root'
})
class GuardAuthService {

	constructor(private router: Router, private MenuService: MenuService, private SessionService: SessionService, private NavController: NavController) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		return this.canForwardRoute(state);
	}

	private async canForwardRoute(state?: RouterStateSnapshot): Promise<boolean> {

		// console.log(state.url);

		// console.log("session on guard ->", this.SessionService.HasSession());


		if (!this.SessionService.HasSession())

			this.NavController.navigateRoot(EnumAppMenu.Login);


		else {

			/**
			 * 
			 */
			const menusStatic: string[] = [EnumAppMenu.Notifications, EnumAppMenu.Settings, EnumAppMenu.Account]

			if (!menusStatic.some(it => it.includes(state?.url?.replace('/', '')))) {

				const menuAvailabled = await this.MenuService.SetMenuByPath(state?.url || null);

				if (!menuAvailabled) this.NavController.navigateRoot(EnumAppMenu.Error);
			}
		}

		return true;
	}
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	return inject(GuardAuthService).canActivate(next, state);
}

