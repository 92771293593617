<ion-card class="ion-no-margin full">
	<ion-card-header>
		<ion-grid>
			<ion-row>
				<ion-col>
					<ion-card-title>Número de porta</ion-card-title></ion-col>
				<ion-col>
					<ion-buttons class="ion-justify-content-end">
						<ion-button [disabled]="IsDisabled" *ngIf="IdAsset" size="small" fill="clear"
							title="adicionar número de porta" (click)="OnAddNewDoorNumber()">
							<ion-icon name="add-outline" slot="icon-only"></ion-icon>
						</ion-button>
					</ion-buttons>
				</ion-col>
			</ion-row>
		</ion-grid>

	</ion-card-header>
	<ion-card-content>

		<ion-grid class="table">

			<ng-container *ngIf="IdAsset">
				<ion-row class="table-header">
					<ion-col>Nº Porta</ion-col>
					<ion-col>Observações</ion-col>
					<ion-col class="icon-status-size-2"></ion-col>
				</ion-row>

				<ion-row class="table-body with-button-opacity" *ngFor="let item of Data">

					<ion-col>{{item?.Number||'--'}}</ion-col>
					<ion-col>{{item?.Observations||'--'}}</ion-col>
					<ion-col class="flex-justify-end buttons-size-3">
						<ion-button title="visualizar" fill="clear" size="small" (click)="OnView(item)">
							<ion-icon name="eye-outline" slot="icon-only"></ion-icon>
						</ion-button>
						<ion-button color="danger" title="eliminar" fill="clear" size="small"
							(click)="OnDelete(item?.Id)">
							<ion-icon name="trash-outline" slot="icon-only"></ion-icon>
						</ion-button>

					</ion-col>
				</ion-row>

				<no-items *ngIf="Data?.length==0" description="adicione número de porta"></no-items>


			</ng-container>

			<no-items *ngIf="!IdAsset" description="pode adicionar número de porta após criar o espaço"></no-items>

		</ion-grid>
	</ion-card-content>
</ion-card>