import { ProjectService } from '@services/project.service';

import { EnumAppMenu } from '@models/AppMenus';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { SessionService } from '@services/session.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationsService } from '@services/notifications.service';

@Component({
	selector: 'side-menu-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class SideMenuHeaderComponent implements OnInit, OnDestroy {

	User: any;

	public ProjectLogo: string = "";

	private subMenu: Subscription;

	private subCounterNotifications: Subscription;

	private subs: Subscription[] = [];

	public CounterNotifications: number = 0;

	public NavigationPath: string = EnumAppMenu.Notifications;

	constructor(private SessionService: SessionService,
		private NotificationsService: NotificationsService,
		private ProjectService: ProjectService,
		private NavController: NavController) { }

	async ngOnInit() {

		this.subMenu = this.SessionService.Session.subscribe(session => {

			this.User = session?.UserData;

			this.ProjectLogo = this.ProjectService.GetSettings()?.Project?.Logos?.Header || '';
		});



		this.subCounterNotifications = this.NotificationsService.NotificationsCount.subscribe(counterNotifications => {
			this.CounterNotifications = counterNotifications;

			console.log("header notifications: ", this.CounterNotifications);

		});

		this.subs.push(...[this.subMenu, this.subCounterNotifications]);

	}

	ngOnDestroy(): void {
		if (this.subs)
			this.subs.forEach(sub => sub.unsubscribe());
	}

}
