import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';

import { PipesModule } from '../pipes/pipes.module';
import { FooterPageComponent } from './footer-page/footer-page.component';

import { LoadingTableComponent } from './loading-table/loading-table.component';
import { FormErrorsComponent } from './forms/form-errors/form-errors.component';
import { InputBrowseComponent } from './input-browse/input-browse.component';
import { GoogleMapContainerComponent } from './google-map-container/google-map-container.component';

import { PasswordToggleComponent } from './password-toggle/password-toggle.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { NoItemsComponent } from './no-items/no-items.component';

import { PaginationComponent } from './pagination/pagination.component';
import { HeaderPageComponent, PopoverHeaderComponent } from './header-page/header-page.component';
import { DirectivesModule } from './../directives/directives.module';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { TextEditorQuillComponent } from './text-editor-quill/text-editor-quill.component';
import { SelectEditionComponent } from './select-edition/select-edition.component';
import { SidemMenuComponent } from './side-menu/side-menu.component';
import { SideMenuHeaderComponent } from './side-menu/header/header.component';
import { SideMenuContentComponent } from './side-menu/content/content.component';
import { ModalsModule } from '@modals/modals.module';
import { AgeGroupsComponent } from './forms/age-groups/age-groups.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { DashboardComponent } from './cards/dashboard/dashboard.component';
import { SimpleChartComponent } from './charts/simple/simple.component';
import { MenuConfigurationsComponent } from './configurations/menu/menu.component';
import { EditionConfigurationsComponent } from './configurations/edition/edition.component';
import { AddressGoogleMapComponent } from './forms/address-google-map/address-google-map.component';
import { AtDl59CrudComponent } from './financial-management/at-dl-59/crud/crud.component';
import { AtDl59ListComponent } from './financial-management/at-dl-59/list/list.component';
import { SchoolSocialActionCrudComponent } from './financial-management/school-social-action/crud/crud.component';
import { SchoolSocialActionListComponent } from './financial-management/school-social-action/list/list.component';
import { WorksInvestmentsCrudComponent } from './financial-management/works-investments/crud/crud.component';
import { WorksInvestmentsListComponent } from './financial-management/works-investments/list/list.component';
import { PermissionsConfigurationsComponent } from './configurations/permissions/permissions.component';
import { InputRadioSearchComponent } from './input-radio-search/input-radio-search.component';
import { InputSelectSearchComponent } from './input-select-search/input-select-search.component';
import { IconStatusComponent } from './icon-status/icon-status.component';
import { BudgetIndicatorsComponent } from './cards/budget-indicators/budget-indicators.component';
import { ManageFilesComponent } from './manage-files/manage-files.component';
import { DoorNumberFormComponent } from './cards/door-number/door-number.component';

const components =
	[
		PasswordToggleComponent,
		UserAvatarComponent,
		NoItemsComponent,
		SelectEditionComponent,
		ImageCropperComponent,
		TextEditorQuillComponent,
		HeaderPageComponent,
		PopoverHeaderComponent,
		PaginationComponent,
		FooterPageComponent,
		SidemMenuComponent,
		SideMenuHeaderComponent,
		SideMenuContentComponent,
		GoogleMapContainerComponent,
		InputBrowseComponent,
		AgeGroupsComponent,
		FormErrorsComponent,
		LoadingTableComponent,
		BarChartComponent,
		SimpleChartComponent,
		DashboardComponent,
		MenuConfigurationsComponent,
		EditionConfigurationsComponent,
		AddressGoogleMapComponent,
		WorksInvestmentsListComponent,
		WorksInvestmentsCrudComponent,
		SchoolSocialActionListComponent,
		SchoolSocialActionCrudComponent,
		AtDl59ListComponent,
		AtDl59CrudComponent,
		PermissionsConfigurationsComponent,
		InputRadioSearchComponent,
		InputSelectSearchComponent,
		IconStatusComponent,
		BudgetIndicatorsComponent,
		ManageFilesComponent,
		DoorNumberFormComponent
	];

const componentsModules = [

]

@NgModule(
	{
		declarations: [components],
		exports: [
			components,
			componentsModules
		],
		imports: [
			CommonModule,
			IonicModule,
			PipesModule,
			FormsModule,
			ReactiveFormsModule,
			DirectivesModule,
			RouterModule,
			GoogleMapsModule,
			ScrollingModule,
			componentsModules
		],
		schemas: [
			CUSTOM_ELEMENTS_SCHEMA
		]
	})
export class ComponentsModule { }
