import { ProjectService } from '@services/project.service';
import { ToastController } from '@ionic/angular';
import { FormGroup } from '@angular/forms';
import { GoogleMapsService } from '@services/google-maps.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';
import { environment } from '@environments/environment';
import { AsyncUtils } from '@utils/AsyncUtils';

export enum ENUM_MAP_ADDRESS_TYPE {
	POINT = "1",
	POLYGON = "2",
	EXTERNAL = "3"

}

@Component({
	selector: 'address-google-map',
	templateUrl: './address-google-map.component.html',
	styleUrls: ['./address-google-map.component.scss'],
})
export class AddressGoogleMapComponent implements OnInit, OnChanges {

	@Input('form') FormGroup: FormGroup | any;
	@Input('mapOptions') MapOptions: any;
	@Input('mapId') MapId: string;
	@Input('label') Title: string;
	@Input('polygonUrl') PolygonUrl: string;
	@Input('polyline') Polyline: { lat: number, lng: number }[];

	@Input('size') Size: 'small' | 'large' = 'large';

	@Input('typeOptions') TypeOptions: string[] = [ENUM_MAP_ADDRESS_TYPE.POINT];

	@Input('required') Required: boolean = false;

	public TypesAddressMap: { Id: string, Name: string, Disabled: boolean }[];

	public ENUM_MAP_ADDRESS_TYPE = ENUM_MAP_ADDRESS_TYPE;

	private marker: { original: any, new: any } = { original: null, new: null };

	private projectSettings;

	constructor(private GoogleMapsService: GoogleMapsService,
		private ProjectService: ProjectService,
		private ToastController: ToastController) { }

	ngOnInit() {


		this.projectSettings = this.ProjectService.GetSettings();

		this.TypesAddressMap = [
			{ Id: ENUM_MAP_ADDRESS_TYPE.POINT, Name: 'Ponto', Disabled: !this.TypeOptions.find(it => it == ENUM_MAP_ADDRESS_TYPE.POINT) },
			{ Id: ENUM_MAP_ADDRESS_TYPE.POLYGON, Name: 'Área', Disabled: !this.TypeOptions.find(it => it == ENUM_MAP_ADDRESS_TYPE.POLYGON) },
			{ Id: ENUM_MAP_ADDRESS_TYPE.EXTERNAL, Name: 'Externo', Disabled: !this.TypeOptions.find(it => it == ENUM_MAP_ADDRESS_TYPE.EXTERNAL) },
		].filter(it => !it.Disabled);

	}

	ngOnChanges(changes: SimpleChanges): void {
		console.log(changes);

	}



	/**
	 * 
	 * @param formgroup 
	 * @returns 
	 */
	public async OnSearchStreet(formgroup: FormGroup = this.FormGroup) {

		// if (this.MapOptions.gestureHandling == 'none') return;

		const address = ((formgroup.get('Address').value || '') + " " +
			(formgroup.get('ZipCode').value || '') + " " +
			// (formgroup.get('District').value || '')
			// (formgroup.get('County').value || '') + " " +
			(formgroup.get('Locality').value || '')
		).trim().replace(/ /g, '+');

		if (!address) return;

		const response: any[] = await this.GoogleMapsService.GetCoordenatesFromStreetName(`${address} ${this.projectSettings?.Project?.Keyword}`);

		console.log(response);

		if (!response || response.length == 0) return this.showToast('Sem resultados para a morada indicada!');

		const coords = response[0]?.geometry?.location || { lat: null, lng: null }

		console.log(formgroup, coords);

		formgroup.get('Coordinates').get('Latitude').setValue(coords.lat);
		formgroup.get('Coordinates').get('Longitude').setValue(coords.lng);
		formgroup.get('Coordinates').markAsDirty();
		formgroup.get('Coordinates').markAsTouched();

	}

	/**
	 * 
	 * @param formgroup 
	 */
	public OnEnabledMarker(formgroup: FormGroup = this.FormGroup) {

		const tmp = Object.assign({}, this.MapOptions)

		tmp.gestureHandling = tmp.gestureHandling == 'auto' ? 'none' : 'auto';

		if (tmp.gestureHandling == 'none') {
			formgroup.markAllAsTouched();
			formgroup.markAsDirty();

			if (this.marker) {
				formgroup.get('Coordinates').get('Latitude').setValue(this.marker.new?.Latitude);
				formgroup.get('Coordinates').get('Longitude').setValue(this.marker.new?.Longitude);
			}
		}
		else
			this.marker.original = formgroup.get('Coordinates').value;

		this.MapOptions = tmp;
	}

	/**
	 * 
	 * @param formgroup 
	 */
	public async OnCancelRepositionMarker(formgroup: FormGroup = this.FormGroup) {

		const tmp = Object.assign({}, this.MapOptions);

		tmp.gestureHandling = 'none';

		this.MapOptions = tmp;

		formgroup.get('Coordinates').get('Latitude').setValue(this.marker.original?.Latitude);
		formgroup.get('Coordinates').get('Longitude').setValue(this.marker.original?.Longitude);

		await AsyncUtils.Sleep(100);
	}

	/**
	 * 
	 * @param marker 
	 */
	public OnDragMarker = (marker) => {

		this.marker.new = marker;

	}

	/**
	 * 
	 * @param message 
	 * @param duration 
	 * @returns 
	 */
	private async showToast(message: string, duration: number = 3000) {
		const toast = await this.ToastController.create(
			{
				duration: duration,
				message: message,
				cssClass: "default-toast",
				position: "top",
				mode: "ios",
				buttons:
					[
						{
							icon: 'close',
							role: EnumGenericRoleActionAlerts.Cancel,
							handler: () => { if (toast) toast.dismiss(); }
						}
					]
			});

		await toast.present();

		return toast;
	}

}
