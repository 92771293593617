<ion-header class="large">
	<ion-toolbar>

		<ion-buttons slot="start">

			<ion-menu-button *ngIf="!BackPath" color="dark"></ion-menu-button>
			<ion-back-button [defaultHref]="BackPath" color="dark" (click)="OnGoBack()"></ion-back-button>

			<select-edition [enabled]="IsEnabled" (edition)="OnChangeEdition($event)"></select-edition>
		</ion-buttons>

		<ion-grid>
			<ion-row>
				<ion-col style="padding: 16px;">
					<ion-row class="title ion-justify-content-end">{{User?.Name||User?.Username||'--'}}</ion-row>
					<ion-row class="subtitle ion-justify-content-end">{{ User?.Place?.Name || User?.Template?.Name ||
						Entity?.Name ||
						'--'}}</ion-row>
				</ion-col>
				<ion-col style="max-width: 60px;" class="ion-justify-content-end">
					<user-avatar (click)="OnShowDefinitions($event)" [name]="User?.Name||User?.Username"></user-avatar>
				</ion-col>
			</ion-row>
		</ion-grid>

	</ion-toolbar>
</ion-header>