export class StringUtils 
{
    /**
     * Obtem as iniciais de uma string e devolve resultado em uppercase.
     * @param str string
     * @returns 
     */
    public static GetInitials(value)
    {
        if (!value) return '';

        if (typeof value === 'string' || value instanceof String) return value.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();

        return value;
    }

    /**
     * Recebe como param um string e coloca em uppercase a primeira letra de cada palavra existente na string.
     * @param value string
     * @returns 
     */
    public static UppercaseWords(value): string
    {
        if (typeof value === 'string' || value instanceof String) return value.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase());

        return value;
    }

    /**
     * Recebe como param duas strings para comparar se o seu valor é igual.
     * A comparação vai fazer uso do metodo localeCompare com o sensitivity: 'base' como valor default.
     * @param valueA string
     * @param valueB string
     * @param sensitivity string "base" | "accent" | "case" | "variant
     * @returns 
     */
    public static AreValuesEqual(valueA: string, valueB: string, sensitivity: "base" | "accent" | "case" | "variant" = "base"): boolean
    {
        if (typeof valueA !== 'string' || typeof valueB !== 'string') return false;

        return valueA.localeCompare(valueB, undefined, { sensitivity: sensitivity }) === 0;
    }

    /**
     * Normaliza string removendo acentos ou outros chars especificos de linguagem.
     * @param value string
     * @returns 
     */
    public static Normalize(value): string
    {
        if (typeof value === 'string' || value instanceof String) return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

        return value;
    }

    /**
     * Recebe uma string como parametro efectua trim() e remove white space duplicado.
     * @param value string to trim
     * @returns 
     */
    public static Trim(value):string
    {
        if (typeof value === 'string' || value instanceof String) return value.trim().replace(/\s+/g, ' ');

        return value;
    }

    /**
     * Recebe uma string como parametro e remove white todo o white space existente.
     * @param value string para remover todo o espaços
     * @returns 
     */
    public static RemoveWhiteSpace(value)
    {
        if (typeof value === 'string' || value instanceof String) return value.replace(/\s+/g, '');

        return value;
    }

    /**
     * copia a string e retorna o resultado da operacao
     * @param text 
     * @returns 
     */
    public static ClipboardText(text:string = ''):boolean
    {
        if (!text) return false;

        try {
            
            navigator.clipboard.writeText(text);

            console.log("clipboard -> ", text);

            return true;
        
        } catch (error) {
            
            console.log(error);

            return false;
            
        }
    }
}

