import { IKeyValue, ILocationModel, LocationModel } from "./Common";
import { IEditionCompletedModel } from "./Edition";

export interface ICompetitionEventsModel {
    IdEntity: number;
    IdChampionship: number;
    IdEdition: number;
    Name: string;
    Description: string;
    Index?: number;
    Date: string;
    IdLocation?: number;
    IdRouteFile?: number;
    IsInsideEntity?: boolean;
    HasClassification?: boolean;
    CountForClassification?: boolean;
    IsTimeCounting: boolean;
    IsFinished?: boolean;
    IsCanceled?: boolean;
    RegistrationOpened: boolean;
    Observations?: string;
    Edition?: IEditionCompletedModel;
    Location: ILocationModel;
    StartLocation: ILocationModel;
    EndLocation: ILocationModel;
    Modality: IKeyValueModel;
    RouteType: IKeyValueModel;

    EventAgeGroupModalityInfoList: IModalityAgeGroupList[];
    EventAthleteList: any[];
    Id: number;
    RegistDate?: string;
    RegistUser?: number;
    RouteFile: IFileModel;
    RulesFile: IFileModel;
    Image: IFileModel;

    OriginalDate?: string;
    TempDate?: string;
    TempHours?: string;

    Status?: { Id: number, Name: string, Icon?: string };
}

export class CompetitionEventsModel implements ICompetitionEventsModel {

    IdEntity: number;
    IdChampionship: number;
    IdEdition: number;
    Name: string;
    Description: string;
    Index?: number;
    Date: string;
    IdLocation?: number;
    IdRouteFile?: number;
    IsInsideEntity?: boolean;
    HasClassification?: boolean;
    CountForClassification?: boolean;
    IsTimeCounting: boolean;
    IsFinished?: boolean;
    IsCanceled?: boolean;
    RegistrationOpened: boolean;
    Observations?: string;
    Edition?: IEditionCompletedModel;
    Location: ILocationModel;
    StartLocation: ILocationModel;
    EndLocation: ILocationModel;
    Modality: IKeyValueModel;
    RouteType: IKeyValueModel;

    EventAgeGroupModalityInfoList: IModalityAgeGroupList[];
    EventAthleteList: any[];
    Id: number;
    RegistDate?: string;
    RegistUser?: number;
    RouteFile: IFileModel;
    RulesFile: IFileModel;
    Image: IFileModel;

    OriginalDate?: string;
    TempDate?: string;
    TempHours?: string;

    Status?: { Id: number, Name: string, Icon?: string };

    constructor(data: Partial<CompetitionEventsModel> = {}) {
        data = data || {};

        this.Id = data.Id || 0;
        this.IdEntity = data.IdEntity || 0;
        this.IdChampionship = data.IdChampionship || 0;
        this.IdEdition = data.IdEdition || 0;
        this.Index = data.Index || 0;
        this.IdLocation = data.IdLocation || 0;
        this.IdRouteFile = data.IdRouteFile || 0;
        this.Name = data.Name || null;
        this.Description = data.Description || null;
        this.Date = data.Date || null;
        this.IsInsideEntity = data.IsInsideEntity ?? null;
        this.HasClassification = data.HasClassification ?? null;
        this.CountForClassification = data.CountForClassification ?? null;
        this.IsTimeCounting = data.IsTimeCounting ?? null;
        this.IsFinished = data.IsFinished ?? null;
        this.IsCanceled = data.IsCanceled ?? null;
        this.RegistrationOpened = data.RegistrationOpened ?? null;
        this.Observations = data.Observations || null;
        this.Edition = data.Edition || null;
        this.Location = new LocationModel(data.Location);
        this.StartLocation = new LocationModel(data.StartLocation);
        this.EndLocation = new LocationModel(data.EndLocation);
        this.RouteFile = data?.RouteFile || null;
        this.RulesFile = data?.RulesFile || null;
        this.Modality = data?.Modality || null;
        this.RouteType = data?.RouteType || null;
        this.EventAgeGroupModalityInfoList = data.EventAgeGroupModalityInfoList || null;
        this.EventAthleteList = data.EventAthleteList || null;
        this.RegistDate = data.RegistDate || null;
        this.RegistUser = data.RegistUser || null;
        this.Image = data.Image || null;
        this.OriginalDate = data.OriginalDate || null;

        this.Status = { Id: 1, Name: 'Aberto', Icon: '' };

        if (this.IsCanceled)
            this.Status = { Id: 1, Name: 'Cancelado', Icon: '' };
        else if (this.IsFinished)
            this.Status = { Id: 2, Name: 'Terminado', Icon: '' };

    }

}



export interface ITeamsModel {
    Name: string;
    Description: string
    IdOrganization?: number;
    IdContact?: number;
    IdLocation?: number;
    IsFederated: false,
    IsEnabled: boolean;
    Observations?: string;
    Contact: {
        Phone: string,
        MobilePhone: string,
        Email: string
    };
    Location?: {
        Id: number,
        Name: string,
        Coordinates: {
            Latitude: string,
            Longitude: string
        }
    };
    Organization?: string;
    TeamPersonList?: any[],
    Id: number;
    RegistDate?: string;
    RegistUser?: number;
}

export interface IFileModel {
    Id: number;
    IsActive?: boolean;
    PhysicalName?: string;
    OriginalName?: string;
    Type?: string;
    Path?: string;
    Code?: string;
    ExistInRepository?: boolean;
    ByteData?: string;
    UploadFileData?: string;
    Base64Data?: string;
    AvailableForCDN?: boolean;
    IsImageType?: boolean;
}

export interface IModalityAgeGroupList {
    IdEvent?: number;
    IdModality?: number;
    IdRouteType?: number;
    IdEditionAgeGroup?: number;
    PredictedStartHour: number;
    PredictedStartMinute: number;
    PredictedEndHour?: number;
    PredictedEndMinute?: number;
    RealStartTime?: number;
    RealEndTime?: number;
    MinDistanceInMeters?: number;
    MaxDistanceInMeters?: number;
    MinTimeInSeconds?: number;
    MaxTimeInSeconds?: number;
    IdStartLocation?: number;
    IdEndLocation?: number;
    MinSets: number;
    MinTimeInSecondsBySets?: number;
    IsTimeCounting?: boolean;
    IsCanceled?: boolean;
    IsFinished?: boolean;
    Observations?: string;
    Modality?: IKeyValueModel;
    RouteType?: string;
    EditionAgeGroup?: IEditionAgeGroupModel,
    StartLocation?: any,
    EndLocation?: any,
    Id: number;
    RegistDate?: string;
    RegistUser?: number;
}
export interface IKeyValueModel {
    Id: number;
    LabelText: string;
    Name: string;
    RegistDate?: string;
    RegistUser?: number;
}

export interface IEditionAgeGroupModel {
    IdEdition: number;
    IdAgeGroup: number;
    Observations: string;
    AgeGroup: IAgeGroupModel,
    EditionAgeGroupModalityInfoList?: IModalityAgeGroupList[],
    Id: number;
    RegistDate: string;
    RegistUser: number;
}

export interface IAgeGroupModel {
    IdBaseAgeGroup: number;
    Acronym: string;
    IdGender: number;
    BaseAgeGroup: {
        Name_Singular: string;
        Name_Plural: string;
        IsTrainingClass: true,
        ForDisablePerson: false,
        IdMinAgeGroupRequired: number;
        IdMaxAgeGroupRequired: number;
        MinDateRequired_YearsBehind: number;
        MinDateRequired_Month: number;
        MinDateRequired_Day: number;
        MaxDateRequired_YearsBehind: number;
        MaxDateRequired_Month: number;
        MaxDateRequired_Day: number;
        MinAgeGroupRequired: string;
        MaxAgeGroupRequired: string;
        LabelText_Singular: string;
        LabelText_Plural: string;
        Id: number;
        RegistDate?: string;
        RegistUser?: number;
    },
    Gender: any,
    Id: number;
    RegistDate?: string;
    RegistUser?: number;
}

export interface ICompetitionResult {
    Date: string,
    Id: string
    Name: string,
    ParticipatingAthletes: number,
    ParticipatingTeams: number,
    RegisteredAthletes: number,
    RegisteredTeams: number
}

export interface ICompetitionAthleteDeliveryDetails {

    DeliveredIdentifierChip: boolean,
    DeliveredIdentifierCode_Value: string,
    DeliveredIdentifierNumber_Value: string,
    DeliveredIdentifierDorsal: boolean,
    DeliveredIdentifierDorsal_Value: string,
    DeliveredShirt: boolean,
    DeliveredShirt_Value: string,
    Observations: string,
    IdEditionAthlete: string,
    DeliveredOthers: IKeyValue[],
    Id: string
}

export enum EnumRouteTypes {
    PointToPoint = 2,
    Circular = 3
}