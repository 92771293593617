import { environment } from '@environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'footer-page',
	templateUrl: './footer-page.component.html',
	styleUrls: ['./footer-page.component.scss'],
})
export class FooterPageComponent implements OnInit {

	@Input('footerTitle') FooterTitle:string = "Powerby";

	public Version: string;

	public Author: { Name?: string, Website?: string, Logo: { Footer: string } };

	constructor() { }

	ngOnInit() {

		this.Version = environment.Version || '';

		const exampleVarTranslation = "Boldapps";

		// this.footerTitle = this.footerTitle || $localize`:@@footer.madeBy:Made with ❤️ | ${exampleVarTranslation}`;
		this.Author = environment.Author || { Name: '', Website: '', Logo: null };
		// this.FooterTitle = "Powered by";

	}

}
