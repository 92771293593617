<ion-card class="chart">
	<ion-card-header>
		<ion-card-title style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;">{{Data?.Title || ''}}</ion-card-title>
	</ion-card-header>
	<ion-card-content style="min-height: 260px;">

		<ng-container [ngSwitch]="Data.IdType">
			<ng-container *ngSwitchCase="1">
				<chart-simple [data]="Data"></chart-simple>
			</ng-container>
			<ng-container *ngSwitchDefault>
				<bar-chart [data]="Data"></bar-chart>
			</ng-container>
		</ng-container>
	</ion-card-content>
</ion-card>