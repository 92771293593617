import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultTextOrArrayValue, DefaultTextOrValue, DateFromNow, SanitizeHTML, FilterByFields, SecurityPipe } from './generic.pipe';

const importedPipes =
	[
		DefaultTextOrArrayValue,
		DefaultTextOrValue,
		SanitizeHTML,
		DateFromNow,
		FilterByFields,
		SecurityPipe
	];

@NgModule(
	{
		imports:
			[
				CommonModule
			],
		declarations: importedPipes,
		exports: importedPipes
	})
export class PipesModule { }
