<ion-list-header class="card">
	<ion-item class="full">
		<ion-grid class="full">

			<ion-row class="ion-justify-content-center">
				<ion-col>
					<ion-img class="logo" [src]="ProjectLogo" />
				</ion-col>
				<ion-col>
					<ion-buttons class="ion-justify-content-end">
						<ion-button id="notification-button"  [routerLink]="[NavigationPath]" routerLinkActive="selected">
							<ion-badge id="notifications-badge" color="danger" *ngIf="CounterNotifications > 0 && CounterNotifications <= 9">{{CounterNotifications}}</ion-badge> 
							<ion-badge id="notifications-badge" color="danger" *ngIf="CounterNotifications > 9">+9</ion-badge> 
							<!-- <ion-badge id="notifications-badge" color="danger">3</ion-badge>  -->
							<ion-icon name="notifications-outline" slot="icon-only"></ion-icon>
						</ion-button>
					</ion-buttons>
				</ion-col>
			</ion-row>

		</ion-grid>

	</ion-item>
</ion-list-header>