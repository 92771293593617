<form [formGroup]="FormGroup" *ngIf="FormGroup">

	<ion-grid class="full">
		<ion-row>
			<ion-col size="12">
				<ion-title class="ion-no-padding ion-padding-top">{{Title}}</ion-title>
			</ion-col>

			<ion-col sizeXs="12" sizeMd="6" sizeXl="5">

				<ion-row *ngIf="TypesAddressMap?.length>1">
					<ion-col class="no-padding-left">
						<ion-radio-group formControlName="MapType">

							<ion-item [disabled]="FormGroup?.get('MapType').disabled">
								<ion-label position="stacked" i18n=":@@competition_form.active_subscription"
									[attr.required]="Required">Tipo</ion-label>
								<ion-grid>

									<ion-row>
										<ion-col style="padding: 0 12px 0 0;" *ngFor="let item of TypesAddressMap">
											<ion-item lines="none" [disabled]="FormGroup?.get('MapType').disabled">
												<ion-label i18n=":@@competition_form.yes">{{item?.Name}}</ion-label>
												<ion-radio slot="start" [value]="item?.Id"></ion-radio>
											</ion-item>
										</ion-col>

									</ion-row>
								</ion-grid>
							</ion-item>

						</ion-radio-group>
					</ion-col>
				</ion-row>

				<ion-row>

					<ng-container *ngIf="Size!='small'">

						<ion-col size="6" class="no-padding-left">
							<ion-item class="full" lines="full" fill="outline">
								<ion-label position="stacked" i18n=":@@parish_form_locality.distric"
									[attr.required]="Required">Distrito</ion-label>
								<ion-input formControlName="District" placeholder="Indique o distrito"></ion-input>
							</ion-item>

							<form-errors [control]="FormGroup.get('District')"></form-errors>
						</ion-col>

						<ion-col size="6" class="no-padding-right">
							<ion-item class="full" lines="full" fill="outline">
								<ion-label position="stacked" i18n=":@@parish_form_locality.county"
									[attr.required]="Required">Concelho</ion-label>
								<ion-input formControlName="County" placeholder="Indique o concelho"></ion-input>
							</ion-item>

							<form-errors [control]="FormGroup.get('County')"></form-errors>
						</ion-col>

					</ng-container>

					<ion-col size="8" class="no-padding-left">

						<ion-item class="full" lines="full" fill="outline">
							<ion-label position="stacked" i18n=":@@parish_form_locality.locality"
								[attr.required]="Required">Localidade</ion-label>
							<ion-input formControlName="Locality" placeholder="Indique a localidade"></ion-input>
						</ion-item>

						<form-errors [control]="FormGroup.get('Locality')"></form-errors>
					</ion-col>

					<ion-col size="4" class="no-padding-right">
						<ion-item class="full" lines="full" fill="outline">
							<ion-label position="stacked" i18n=":@@parish_form_locality.zipcode"
								[attr.required]="Required">Código
								postal</ion-label>
							<ion-input placeholder="0000-000" formControlName="ZipCode"></ion-input>
						</ion-item>

						<form-errors [control]="FormGroup.get('ZipCode')"></form-errors>
					</ion-col>

				</ion-row>

				<ion-row class="ion-margin-bottom">

					<ion-col class="no-padding-left">

						<ion-item class="full" lines="full" fill="outline">
							<ion-grid class="full">
								<ion-row>
									<ion-label style="margin-bottom: 11px;" position="stacked"
										i18n=":@@address_default_form.name" [attr.required]="Required">Rua</ion-label>
								</ion-row>
								<ion-row>
									<ion-col>
										<ion-input formControlName="Address" placeholder="Indique a rua"
											(keyup.enter)="OnSearchStreet(FormGroup)"></ion-input>
									</ion-col>
									<ion-col [hidden]="FormGroup.disabled"
										[ngStyle]="{'max-width': FormGroup.get('MapType').value == ENUM_MAP_ADDRESS_TYPE.POINT ? '110px' : '60px' }"
										class="display-flex ion-justify-content-end">

										<ng-container *ngIf="MapOptions?.gestureHandling == 'auto'">
											<ion-button title="cancelar" (click)="OnCancelRepositionMarker(FormGroup)">
												<ion-icon name="close-outline" slot="icon-only"></ion-icon>
											</ion-button>
											<ion-button title="guardar posição" (click)="OnEnabledMarker(FormGroup)">
												<ion-icon name="checkmark-outline" slot="icon-only"></ion-icon>
											</ion-button>
										</ng-container>

										<ng-container *ngIf="MapOptions?.gestureHandling != 'auto'">

											<ion-button title="localizar" (click)="OnSearchStreet(FormGroup)">
												<ion-icon name="locate-outline" slot="icon-only"></ion-icon>
											</ion-button>
											<ion-button
												*ngIf="FormGroup.get('MapType').value==ENUM_MAP_ADDRESS_TYPE.POINT"
												title="corrigir posição" (click)="OnEnabledMarker(FormGroup)">
												<ion-icon name="pin-outline" slot="icon-only"></ion-icon>
											</ion-button>
										</ng-container>

									</ion-col>
								</ion-row>
							</ion-grid>
						</ion-item>

						<form-errors [control]="FormGroup?.get('Address')"></form-errors>
					</ion-col>

				</ion-row>

			</ion-col>

			<ion-col sizeXs="12" sizeMd="6" sizeXl="7" class="ion-padding-horizontal no-padding-right">

				<google-map-container *ngIf="MapOptions" [ngClass]="{'small': Size=='small', 'medium': Size!='small' }"
					[id]="MapId" [options]="MapOptions" [pin]="FormGroup.get('Coordinates').value"
					[onDrag]="OnDragMarker"
					[polyline]="FormGroup.get('MapType').value == ENUM_MAP_ADDRESS_TYPE.EXTERNAL?Polyline:null"
					[polygonUrl]="FormGroup.get('MapType').value == ENUM_MAP_ADDRESS_TYPE.POLYGON?PolygonUrl:null"></google-map-container>

			</ion-col>
		</ion-row>
	</ion-grid>
</form>