import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'budget-indicators',
	templateUrl: './budget-indicators.component.html',
	styleUrls: ['./budget-indicators.component.scss'],
})
export class BudgetIndicatorsComponent implements OnInit {

	@Input('total') Total: number = 0;
	@Input('spend') Spend: number = 0;
	@Input('available') Available: number = 0;
	@Input('captive') Captive: number = 0;

	constructor() { }

	ngOnInit() { }

}
