<ion-grid class="table height-toolbar-2">

	<ion-row class="table-header">
		<ion-col size="1" class="flex-align-center">#</ion-col>
		<ion-col size="1" class="flex-align-center" i18n=":@@configurations_menu_comp.icon">Icon</ion-col>
		<ion-col size="4" class="flex-align-center" i18n=":@@configurations_menu_comp.name">Nome</ion-col>
		<ion-col size="2" class="flex-justify-start" i18n=":@@configurations_menu_comp.path">Endereço</ion-col>
		<ion-col size="1" class="flex-justify-center"
			i18n=":@@configurations_menu_comp.availability">Disponível</ion-col>
		<ion-col size="3" class="flex-align-center"></ion-col>
	</ion-row>

	<!-- <ng-container *cdkVirtualFor="let item of Data; let itemBounds = bounds;"> -->
	<ng-container *ngFor="let item of Data;">

		<ion-row class="table-body table-inner-header">

			<ion-col size="6" class="flex-justify-start">{{ item?.Platform?.LabelText ||'--' }}</ion-col>
			<ion-col></ion-col>

		</ion-row>

		<ion-reorder-group (ionItemReorder)="OnReorder($event, item.Menus)" [disabled]="!Reorder">

			<ion-row *ngFor="let menu of item?.Menus" style="display: block;">
				<ion-item class="ion-no-margin">
					<ion-reorder slot="end"></ion-reorder>
					<ion-grid class="ion-no-margin ion-no-padding">

						<ion-row class="table-body with-button-opacity">
							<ion-col size="1" class="flex-align-center">
								{{ menu?.Id }}
							</ion-col>
							<ion-col size="1" class="flex-align-center">
								<ion-icon [name]="menu?.NavigationIcon"></ion-icon>
							</ion-col>
							<ion-col size="4" class="flex-align-center">{{ menu?.Name }}</ion-col>

							<ion-col size="2" class="flex-justify-start">
								{{ menu?.NavigationPath }}
							</ion-col>
							<ion-col size="1" class="flex-justify-center">
								<ion-icon name="ellipse-sharp" [color]="menu?.IsPublic ? 'success' : 'warning' ">
								</ion-icon>
							</ion-col>
							<ion-col size="3" class="flex-justify-end">

								<ng-container *ngIf="!Reorder">

									<ion-button fill="clear" color="dark" size="small"
										[actionsPermissions]="EnumActionsPermissions.VIEW" (click)="OnView(menu)">
										<ion-icon name="eye-outline" slot="icon-only"></ion-icon>
									</ion-button>

									<ion-button fill="clear" size="small" color="dark" (click)="OnEdit(menu)"
										[actionsPermissions]="EnumActionsPermissions.CREATEANDEDIT">
										<ion-icon name="create-outline" slot="icon-only"></ion-icon>
									</ion-button>

									<ion-button fill="clear" size="small" color="danger" (click)="OnDelete(menu)"
										[actionsPermissions]="EnumActionsPermissions.DELETE">
										<ion-icon name="trash-outline" slot="icon-only"></ion-icon>
									</ion-button>
								</ng-container>

							</ion-col>
						</ion-row>

					</ion-grid>
				</ion-item>

				<ion-item class="ion-no-margin" *ngFor="let child of menu?.Children">
					<!-- <ion-reorder slot="end"></ion-reorder> -->
					<ion-grid class="ion-no-margin ion-no-padding">
						<ion-row class="table-body with-button-opacity child">

							<ion-col size="1" class="flex-align-center">{{ child?.Id }}</ion-col>
							<ion-col size="1" class="flex-align-center">
								<ion-icon [name]="child?.NavigationIcon"></ion-icon>
							</ion-col>

							<ion-col size="4" class="flex-align-center">{{ child?.Name }}</ion-col>

							<ion-col size="2" class="flex-justify-start">
								{{ child?.NavigationPath }}
							</ion-col>
							<ion-col size="1" class="flex-justify-center">

								<ion-icon name="ellipse-sharp" [color]="child?.IsPublic ? 'success' : 'warning' ">
								</ion-icon>

							</ion-col>
							<ion-col size="3" class="flex-justify-end">

								<ng-container *ngIf="!Reorder">
									<ion-button fill="clear" size="small" color="dark" (click)="OnView(child)"
										[actionsPermissions]="EnumActionsPermissions.VIEW">
										<ion-icon name="eye-outline" slot="icon-only"></ion-icon>
									</ion-button>

									<ion-button fill="clear" size="small" color="dark" (click)="OnEdit(child)"
										[actionsPermissions]="EnumActionsPermissions.CREATEANDEDIT">
										<ion-icon name="create-outline" slot="icon-only"></ion-icon>
									</ion-button>

									<ion-button fill="clear" size="small" color="danger" (click)="OnDelete(child)"
										[actionsPermissions]="EnumActionsPermissions.DELETE">
										<ion-icon name="trash-outline" slot="icon-only"></ion-icon>
									</ion-button>
								</ng-container>

							</ion-col>

						</ion-row>

					</ion-grid>
				</ion-item>

			</ion-row>

		</ion-reorder-group>
	</ng-container>

	<ion-row *ngIf="!Data || Data?.length == 0">
		<ion-col>
			<no-items></no-items>
		</ion-col>
	</ion-row>

	<!-- <pagination-control [totalItems]="Data?.length" (pageChange)="OnChangePage($event)">
	</pagination-control> -->

</ion-grid>