export interface ICoordinates {
    Latitude: string;
    Longitude: string;
}

export class Coordinates implements ICoordinates {
    public Latitude: string;
    public Longitude: string;

    constructor(data: Partial<ICoordinates> = {}) {
        this.Latitude = data.Latitude || "";
        this.Longitude = data.Longitude || "";
    }
}

export interface ILocationModel {
    Address?: string;
    ZipCode?: string;
    Locality?: string;
    Street?: string;
    District?: string;
    County?: string;
    Coordinates: ICoordinates;
    Id?: number;
    Name?: string;
    RegistDate?: string;
    RegistUser?: number;
}

export class LocationModel implements ILocationModel {
    Address?: string;
    ZipCode?: string;
    Locality?: string;
    Street?: string;
    District?: string;
    County?: string;
    Coordinates: ICoordinates;
    Id?: number;
    Name?: string;
    RegistDate?: string;
    RegistUser?: number;

    constructor(data: Partial<LocationModel> = {}) {

        this.Address = data?.Address || null;
        this.ZipCode = data?.ZipCode || null;
        this.Locality = data?.Locality || null;
        this.Street = data?.Street || null;
        this.District = data?.District || null;
        this.County = data?.County || null;
        this.Name = data?.Name || null;
        this.Coordinates = data?.Coordinates || null;
        this.Id = data?.Id || null;

    }
}

export interface IKeyValue {
    Key: string,
    Value: string
}

export enum EnumPlacesType {
    Continent = '1',
    Country = '2',
    District = '3',
    County = '4',
    Parish = '5'
}

export enum EnumDownloadFormatType {
    Unknown = 0,
    Excel = 1,
    PDF = 2
}

export enum EnumStatus {
    Pending = 1,
    Accepted = 2,
    Rejected = 3,
    PendingResponsibleApproval = 4,
    Finished = 5,
    InProgress = 6,
    Canceled = 7
}

export enum EnumPageRow {
    DEFAULT = 25,
    R25 = 25,
    R50 = 50,
    R100 = 100,
}

export enum EnumStatusInvestiment {
    ApplicationSubmitted = 1,
    Dispatch = 2,
    DocumentsAttachment = 3,
    ContractAward = 4,
    BeginningWork = 5,
    CompletionWork = 6,
    Transferer = 7
}
