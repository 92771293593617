import { IEditionBaseModel } from '@models/Edition';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';
import { EditionService } from '@services/edition.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { AlertController, AlertButton } from '@ionic/angular';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { StorageService } from '@services/storage.service';

@Component({
	selector: 'select-edition',
	templateUrl: './select-edition.component.html',
	styleUrls: ['./select-edition.component.scss'],
})
export class SelectEditionComponent implements OnInit, OnDestroy {

	@Input() enabled: boolean;
	@Output() edition: EventEmitter<any> = new EventEmitter<any>();

	public Editions: IEditionBaseModel[];
	public SelectedEdtion: IEditionBaseModel;

	public Form: FormGroup;

	private subEdtion: Subscription;

	constructor(private AlertController: AlertController,
		private EditionService: EditionService) {

		this.Editions = [
			// 	{ Id: 2019, Name: "2019/2020" },
			// 	{ Id: 2020, Name: "2020/2021" },
			// 	{ Id: 2021, Name: "2021/2022" },
			// 	{ Id: 2022, Name: "2022/2023" }
		];

		this.enabled = this.enabled ?? false;

		this.createFormElements();

	}

	async ngOnInit() {

		await this.loadEditions();

		this.SelectedEdtion = this.EditionService.GetEdition();

		const edition = this.Editions.find(it => this.SelectedEdtion ? it.Id == this.SelectedEdtion.Id : it.IsCurrent);

		console.log(this.SelectedEdtion);

		this.createFormElements(edition);

		if (!this.enabled)
			this.Form.disable();
	}

	private createFormElements(edition?) {

		this.Form = new FormGroup({
			edition: new FormControl(edition || null)
		});

		this.subEdtion = this.Form.get('edition').valueChanges.subscribe(async value => {
			// console.log(value);
			if (this.enabled && (value && value.Id != this.SelectedEdtion.Id)) {

				const { role } = await this.showAlert();

				if (role != EnumGenericRoleActionAlerts.Confirm)
					return this.Form.get('edition').setValue(this.SelectedEdtion);

				if (value) this.SelectedEdtion = value;

				this.EditionService.SetEdition(this.SelectedEdtion);

				this.edition.emit(this.SelectedEdtion);

			}
		});

	}


	private async loadEditions() {

		this.Editions = await this.EditionService.LoadBaseEditions();

		// const edition = this.EditionService.GetEdition();

		// this.SelectedEdtion = this.Editions.find(it => it.Id == edition.Id);

		// this.Form.get('edition').setValue(this.SelectedEdtion);


		if ((this.Editions.length <= 1))
			this.Form.disable();

	}


	ngOnDestroy(): void {

		if (this.subEdtion) this.subEdtion.unsubscribe();
	}

	private async showAlert() {

		const alert = await this.AlertController.create({
			cssClass: 'default',
			header: 'Aviso',
			subHeader: '',
			message: 'Pretende alterar o ano de orçamento',
			backdropDismiss: false,
			buttons: [
				{
					text: 'cancelar',
					role: EnumGenericRoleActionAlerts.Cancel
				},
				{
					text: 'confirmar',
					role: EnumGenericRoleActionAlerts.Confirm
				}
			]
		});

		await alert.present();

		return await alert.onDidDismiss();

	}

}
