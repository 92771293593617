<ion-row [hidden]="totalItems<=itemsPerPage[0]" [ngClass]="disabled ? 'disabled' : '' ">
<!-- <ion-row [hidden]="totalItems==0"> -->
	<ion-col style="display: flex;" class="ion-justify-content-start ion-align-items-end">
		<ion-item>
			<ion-label>Registos por página:</ion-label>
			<ion-select [value]="itemsForPageSelected" [interface]="'popover'" (ionChange)="CalcNumberPages($event)">
				<ion-select-option *ngFor="let item of itemsPerPage" [value]="item">{{item}}</ion-select-option>
			</ion-select>
		</ion-item>
		<p style="position: absolute; top: 41px !important; display: block; font-size: 11px;"><b>total</b>:
			{{totalItems}}</p>
	</ion-col>
	<ion-col style="display: flex;" class="ion-justify-content-end ion-align-items-end">
		<ng-container *ngIf="Pages.length>0">
			<ion-button color="light" [disabled]="page<=1" (click)="PreviousPage()">
				<ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
			</ion-button>
			<ng-container *ngFor="let item of Pages">
				<ion-button
					[hidden]="!((item >= (page==Pages.length ? (page-2) : (page-1))) && (item <= (page==1 ? (page+2): (page+1))))"
					[color]="item==page ? 'primary': 'light'" (click)="GoPage(item)">{{item}}</ion-button>
			</ng-container>
			<ion-button color="light" [disabled]="page>=Pages.length" (click)="NextPage()">
				<ion-icon slot="icon-only" name="chevron-forward-outline"></ion-icon>
			</ion-button>
		</ng-container>
	</ion-col>
</ion-row>