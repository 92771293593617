import { WebService } from '@services/web.service';
import { Injectable } from '@angular/core';
import { IEditionBaseModel, IEditionCompletedModel } from '@models/Edition';
import { EnumStorageKeys, StorageService } from './storage.service';

@Injectable({
	providedIn: 'root'
})
export class EditionService {

	private editionsBase: IEditionBaseModel[];

	private editions: IEditionCompletedModel[];

	private selectedEdition: IEditionBaseModel;

	constructor(
		private WebService: WebService,
		private StorageService: StorageService,
	) { }


	public async Init() {

		this.editionsBase = [];
		this.editions = [];

		this.selectedEdition = await this.StorageService.Get(EnumStorageKeys.Edtion);

		await this.LoadBaseEditions();


	}

	/**
	 * Returns all editions
	 * @returns d
	 */
	public async LoadEditions(force: boolean = false) {

		try {

			if (!force && this.editions.length > 0) return Promise.resolve(this.editions);

			const response = await this.WebService.GetEditionList();

			if (response?.IsSuccessStatus) {
				this.editions = response?.Result || [];


				console.log(this.editionsBase, this.editions);

				return this.editions;
			}

			return [];
		}
		catch (error) {
			return [];
		}
	}

	/**
	 * 
	 * @returns 
	 */
	public async LoadBaseEditions() {

		try {

			if (this.editionsBase.length > 0) return Promise.resolve(this.editionsBase);

			const response = await this.WebService.GetBaseEditionList();

			if (response?.IsSuccessStatus) {
				this.editionsBase = response?.Result || [];

				if (!this.selectedEdition)
					this.selectedEdition = this.editionsBase?.length > 0 ? this.editionsBase.find(it => it.IsCurrent) || this.editionsBase[this.editionsBase.length - 1] : null;
				else if (this.editionsBase?.length > 0) {

					if (!this.editionsBase?.find(it => it?.Id == this.selectedEdition?.Id)) {
						this.selectedEdition = this.editionsBase.find(it => it.IsCurrent) || this.editionsBase[this.editionsBase.length - 1];
						this.StorageService.Delete(EnumStorageKeys.Edtion);
					}
				}
				else
					this.StorageService.Delete(EnumStorageKeys.Edtion);

				return this.editionsBase;
			}

			return [];
		}
		catch (error) {
			return [];
		}
	}

	/**
	 * Set the selected edition
	 * @param edition 
	 */
	public SetEdition(edition) {

		if (!edition) return;

		this.selectedEdition = edition;

		console.log(edition);

		this.StorageService.Save(EnumStorageKeys.Edtion, edition);
	}

	/**
	 * Returns the selected edition
	 * @returns 
	 */
	public GetEdition() {

		return this.selectedEdition;
	}

	public async GetEditionCompleteById(id: number) {

		const response = await this.WebService.GetEditionById(id);

		return response;

	}

	public async SaveEdition(form) {

		const response = await this.WebService.SaveEdition(form);

		return response;

	}

	/**
	 * 
	 * @param id 
	 * @returns 
	 */
	public async DeleteEdition(id: number) {

		const response = await this.WebService.DeleteEdition(id);

		if (response?.IsSuccessStatus && this.editionsBase) this.editionsBase.length = 0;
		if (response?.IsSuccessStatus && this.editions) this.editions.length = 0;

		return response;

	}
}
